import React from "react";
import PropTypes from "prop-types";
import {DownArrowOutlined} from "@web/assets/icons";
import {Dropdown} from "antd";

FilterDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  initialKey: PropTypes.string,
};

function FilterDropdown({items = [], initialKey, onSelect}) {
  const [selectedKey, setSelectedKey] = React.useState(initialKey);

  const onClick = React.useCallback(({key, keyPath, e}) => {
    setSelectedKey(key);
    onSelect && onSelect({key, keyPath, e});
  }, [onSelect, selectedKey])

  const getLabel = (selectedKey) => items.find(item => item.key === selectedKey)?.label || selectedKey?.toUpperCase();

  return <h6><span>Todos</span> &nbsp;<Dropdown menu={{items, onClick}}>
    <a>{getLabel(selectedKey)} <DownArrowOutlined size={18} style={{position: 'relative', bottom: '-5px'}} /></a>
  </Dropdown>
  </h6>
}

export default FilterDropdown;