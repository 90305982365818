import React, {useCallback, useContext} from "react";
import {AppContext} from '@web/hooks/context'
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import PropTypes from "prop-types";

ServiceData.propTypes = {
  serviceId: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  delay: PropTypes.number,
}

function ServiceData(props) {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);

  const list = useSelector((state) =>
    state.Content?.activity?.[props.serviceId]?.list);

  const hasMore = useSelector((state) =>
    !!state.Content?.activity?.[props.serviceId]?.startAfter);

  const loadContent = React.useCallback(() => {
    !!props.serviceId &&
    dispatch(contentActions.loadContent({serviceId: props.serviceId}))
  }, [props.serviceId, dispatch]);

  const pageNext = useCallback(() =>
    dispatch(contentActions.pageContentNext()),
    [dispatch]);

  React.useEffect(() => {
    if (!user) return;
    console.log("ServiceData:useEffect:loadContent:", props.serviceId);
    loadContent();
  }, [user, props.serviceId])

  if (!user?.uid) {
    return null;
  }

  return React.createElement(
    props.component,
    {list, pageNext, hasMore},
  );
}

export default ServiceData;
