import React from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@web/lib/firebase'
// import EmailAuthPage from '@ai-antd/page/EmailAuthPage'
import Loading from '@ai-antd/components/Loading'
import PropTypes from "prop-types";
import {Link} from "wouter";
import {Card} from "antd";

AuthLinkEmailCard.propTypes = {
  title: PropTypes.string,
  // providers: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export function AuthLinkEmailCard({title = "Connect Account", subtitle = "Link", providers = [], redirectTo = '/'}) {
  const [user, loadingUser] = useAuthState(auth)

  if (loadingUser) return <Loading />
  if (!user) return null;
  if (user.emailVerified) return null;

  return <Card className="card-simple card-rounded card-no-body" title={<Link to="/verify-email">Connect Your Email</Link>} bordered={false}>
  </Card>
}

export default AuthLinkEmailCard
