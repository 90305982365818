import React from "react";
// import useCallAssistant from "@web/hooks/useCallAssistant";
import {AppContext, LinesContext} from "@web/hooks/context";
import {Typography, Card, Tooltip} from "antd";
// import {Link} from "wouter";
// import InCallControls from "./InCallControls";
// import Hotline from "./Hotline";
// import VoiceCallsLogsList from "./VoiceCallsLogsList";
// import PhoneNumberSelect from "./PhoneNumberSelect";
// import VoiceCallManager from "./VoiceCallManager";
// import MessengerInfoCard from "./MessengerInfoCard";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {useSelector} from "react-redux";

function MessengerIdentity() {
  const [app, user, claims] = React.useContext(AppContext);
  const [hotlineContext] = React.useContext(LinesContext);
  const assistant = useSelector(state => state.Lines.data?.assistant);
  const [subscription, loading] = useDocumentFirestore(["user", user.uid, "lines", "messenger", "subscriptions", hotlineContext]);
  const isClient = hotlineContext && hotlineContext === claims.data?._h;
  const displayName = isClient ? "Messenger" : hotlineContext?.slice?.(2) || process.env.REACT_APP_DBA;

  return <div className="space space-center-y space-center-x">
    <Tooltip
      defaultOpen={!subscription}
      // open={showVerification || activeCalls.length}
      trigger={["click"]}
      title={
        <Card className="card-simple card-rounded card-transparent" bordered={false}
              style={{width: 360, maxWidth: "96%"}}>
          <div className="space space-center-y space-center-x">
            {isClient ?
              <span className="font-lg bolded">{assistant?.assistantName} for {user.displayName}</span> :
              subscription ?
                <Typography.Text>{subscription.userName}</Typography.Text> :
                <span className="font-lg bolded">Text SUBSCRIBE for full access</span>
            }
          </div>
        </Card>
      }
      // color="green"
      >
    <h1 className="logo-text" title={app.name}>
        {displayName}
        {/*{registered ?*/}
        {/*  <label>*/}
        {/*    &nbsp;*/}
        {/*    <code className="logo-beta muted">ready</code>*/}
        {/*  </label> :*/}
        {/*  <label onClick={register}>*/}
        {/*    &nbsp;*/}
        {/*    {registering ?*/}
        {/*      <ProgressBar visible /> :*/}
        {/*      <code className="logo-beta muted">offline</code>}*/}
        {/*  </label>*/}
        {/*}*/}
      </h1>
    </Tooltip>
    {/*<div>*/}
    {/*  {!!activeCalls.length &&*/}
    {/*    activeCalls.map((callSid) => <InCallControls key={callSid} callSid={callSid} />)*/}
    {/*  }*/}
    {/*</div>*/}
  </div>
}

export default MessengerIdentity;
