import React, {useContext} from "react";
import {AppContext} from "@web/hooks/context";
import Splash from "@ai-antd/components/Splash";
import ConsoleRouter from "./ConsoleRouter";
import RequireLine from "@web/ui/containers/RequireLine";
import HomeMiddleware from "./HomeMiddleware";

export default function Home() {
  const [, user] = useContext(AppContext);
  return (user && !user.isAnonymous) ?
    <HomeMiddleware>
      <RequireLine>
        <ConsoleRouter />
      </RequireLine>
    </HomeMiddleware> :
  <Splash/>;
}
