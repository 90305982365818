import React, {useContext} from "react";
import {auth} from "@web/lib/firebase";
import {WindowContext} from '@web/hooks/context';
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "../Loading";
import GuidesRouter from "./GuidesRouter";
import PropTypes from "prop-types";

GuidesManager.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
  initialIndex: PropTypes.number,
  onCompleteStep: PropTypes.func,
  onExit: PropTypes.func,
}

export default function GuidesManager(props) {
  const [isMobile, width, height] = useContext(WindowContext);
  const [user, claims, loadingUser] = useAuthOven(auth);

  if (loadingUser) {
    return <Loading inline />
  }

  return <GuidesRouter
    user={user}
    list={props.list || []}
    initialStepIndex={props.initialIndex ?? 0}
    isMobile={isMobile}
    width={width}
    height={height}
    onCompleteStep={props.onCompleteStep}
    onExit={props.onExit}
  />
}
