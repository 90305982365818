import React from "react";
import {Button, Card, Col, Row, Typography} from "antd";
import {CheckIcon} from "@web/assets/icons";
import Logo from "./Logo";
import DashboardRow from "./DashboardRow";
import {AppContext, WindowContext} from "@web/hooks/context";
import useQuerystring from "@web/hooks/useQuerystring";
import {Link} from "wouter";

function AuthSuccess() {
  const [, user] = React.useContext(AppContext);
  const [isMobile, width, height] = React.useContext(WindowContext);
  const queryString = useQuerystring();

  if (!user) return null;
  if (user.isAnonymous) return null;

  const onClose = () => {
    window.close();
  };

  const displayName = user.displayName && user.displayName?.split(" ")?.[0] || user.email?.split("@")?.[0];
  return <section className="beta-home-page" style={{
    width,
    height: "100vh",
    padding: isMobile ? "10px 6px" : "10px",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent"
  }}>
    <div style={{display: "flex", flexDirection: "column", height, justifyContent: "center", alignItems: "center"}}>
      <Row style={{width: "100%"}} justify="start">
        <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={6}>
        </Col>
        <Col xs={24} sm={24} md={20} lg={16} xl={16} xxl={12}>
          <div style={{maxWidth: "700px", margin: "0 auto", paddingTop: "5px"}} className="space space-col">
            {queryString["auth-success"] ? <>
              <Typography.Title level={2}>{`Welcome, ${displayName}`}</Typography.Title>
              <Card className="card-simple card-rounded card-no-header" bordered={false}>
                <section className="space space-center-x space-col">
                  <Button ghost onClick={onClose}>
                    <div className="space space-center-y">
                      <CheckIcon className="green-icon" /> <span className="green-icon">Click here to continue</span>
                    </div>
                  </Button>
                </section>
              </Card>
            </> :
            <>
              <Logo name={user.displayName || "Get started"} className="user-display-name" href="/" />
              <div style={{height: isMobile ? "6px" : "16px"}} />
              <Link to="/">Home</Link>
              {/*<Card className="card-simple card-rounded card-no-header" bordered={false}>*/}
              {/*  <AppIntegrationsData filter="all" tags={["email"]}>*/}
              {/*    <AppIntegrationsList/>*/}
              {/*  </AppIntegrationsData>*/}
              {/*</Card>*/}
              {/*<AiStream />*/}
            </>}
          </div>
        </Col>
      </Row>
      <DashboardRow/>
    </div>
  </section>;
}

export default AuthSuccess;
