import React from 'react';
import {useInView} from 'react-intersection-observer';
import {useDispatch} from "react-redux";

function useItemVisibilityLogger(path, threshold = 0.5, delay = 500) {
  const dispatch = useDispatch();
  const [ref] = useInView({
    threshold, delay, onChange: (inView, entry) => {
      dispatch({
        type: "LOG_ITEM_VISIBILITY",
        payload: {
          inView,
          path,
          observedAfter: entry.time,
          fromBottom: inView ? (entry.boundingClientRect.y > entry.rootBounds.height / 2) : null,
        },
      });
    },
  });

  return [ref];
}

export default useItemVisibilityLogger;
