import React from 'react';
import DashboardCenterRow from "./DashboardCenterRow";
// import HotlineIdentity from "./HotlineIdentity";
import GuidesManager from "./guides/GuidesManager";
import ServiceData from "@web/ui/containers/ServiceData";
import MessengerIdentity from "./MessengerIdentity";

MessengerContent.propTypes = {}

function MessengerContent(props) {
  return <div className={`chat-page-wrapper ${props.asOverlay ? "as-overlay" : "as-page"}`}>
    <DashboardCenterRow
      // left={<Card className="card-simple card-h-600 card-glass"></Card>}
      // right={<LinesManager />}
    >
      <section style={{width: 1000, margin: '0 auto'}}>
        <MessengerIdentity />
      </section>
      <ServiceData serviceId="messenger" component={GuidesManager} />
    </DashboardCenterRow>
  </div>
}

export default MessengerContent;
