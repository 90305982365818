import React from 'react';
import DashboardCenterRow from "./DashboardCenterRow";
import GuidesManager from "./guides/GuidesManager";
import ServiceData from "@web/ui/containers/ServiceData";

LineContent.propTypes = {}

function LineContent(props) {
  console.log('LineContent:props:', props);
  return <div className={`chat-page-wrapper ${props.asOverlay ? "as-overlay" : "as-page"}`}>
    <DashboardCenterRow>
      <ServiceData serviceId="progress" component={GuidesManager} />
    </DashboardCenterRow>
  </div>
  }

  export default LineContent;
