import {Device, Call} from "@twilio/voice-sdk";
import {api} from "./api";

/** @typedef {('error' | 'incoming' | 'connecting' | 'connected' | 'disconnecting' | 'disconnected' | 'registered' | 'registering' | 'tokenWillExpire' | 'unregistered')} TwilioEvent */
const voice = {};
// /**
//  * @summary Twilio Voice
//  * @returns {{register: function, getDevice: function, connect: function}}
//  * @constructor
//  */
const TwilioVoice = () => {
  const voiceAuthUrl = process.env.NODE_ENV === "production" ?
    "https://line-v1-7zujc5azra-uw.a.run.app/voice/auth" :
    "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/voice/auth";

  /**
   * @summary Get a Twilio device
   * @param {Device.Options} options
   * @returns {Promise<{device:Device, identity:string}>}
   */
  const getDevice = async (options) => {
    if (voice.device) {
      return Promise.resolve({device: voice.device});
    }
    return api.POST(voiceAuthUrl, {})
    .then((response) => {
      const {token, identity, error} = response;
      if (error) return {error};

      voice.device = new Device(token, {
        appName: 'TheScribe',
        debug: false,
        edge: 'ashburn',
        enableImprovedSignalingErrorPrecision: true,
        allowIncomingWhileBusy: true,
        // logLevel: 1,
        sounds: {},
        ...(options || null),
      });

      return {
        device: voice.device,
        identity,
      };
    })
    .catch((error) => {
      console.log('twilio-twiml-token error', error);
      return {error};
    });
  };

  const registerDevice = async (options) => {
    return voice.device.register()
    .then(() => {
      return {device: voice.device}
    })
    .catch(err => {
      console.log("register error", err.message);
    });
  }

  const updateDeviceToken = () => {
    return api.POST(voiceAuthUrl, {})
    .then((response) => {
      console.log("twilio-voice:updateDeviceToken:", response);
      response.token &&
      voice.device.updateToken(response.token);
    })
    .catch((error) => {
      console.log('twilio-twiml-token error', error);
    });
  }

  /**
   * @async
   * @summary Connect a call
   * @param {Record<string, string>} params
   * @returns {Promise<Call>}
   */
  const connect = async (params) => {
    console.log("connect:", params);
    return voice.device.connect({params});
  }

  return {
    /**
     * @summary Register Twilio device
     * @returns {{device: Device}}
     */
    register: registerDevice,
    /** @returns {Promise<{device:Device, identity:string}>} */
    getDevice,
    connect,
    updateDeviceToken,
  };
}

export default TwilioVoice;
