import React, {useContext, useEffect} from 'react';
import {AppContext} from '@web/hooks/context'
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import appActions from "@web/redux/app/actions";
import Progress from "@ai-antd/page/Progress";

DetailData.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  memberUserId: PropTypes.string,
  preview: PropTypes.object,
};

function DetailData({component, ...props}) {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);

  const item = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const clearActiveDetail = React.useCallback(() => {
    dispatch(appActions.clearActiveDetail());
  }, []);

  useEffect(() => {
    props.path &&
    dispatch(contentActions.loadContext(props.path));
  }, [props.path]);

  if (!user?.uid) {
    return null;
  }

  if (!item) {
    return <Progress />;
  }

  return React.createElement(component, {
    path: props.path,
    item,
    onClose: clearActiveDetail,
  });
}

export default DetailData;
