import React from "react";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {deletePath, updatePath, Fields} from "@web/lib/firestore.db";
import aiActions from "@web/redux/ai/actions";
import {useDispatch} from "react-redux";
import UserTaskProgress from "./UserTaskProgress";
import useChatStream from "@web/hooks/useChatStream";
import now from "lodash/now";

UserTaskProgressManager.propTypes = {
  agentId: PropTypes.string,
  path: PropTypes.string,
  collapsed: PropTypes.bool,
  onAddContextPath: PropTypes.func,
  onRemoveContextPath: PropTypes.func,
};

function UserTaskProgressManager(props) {
  const dispatch = useDispatch();

  const [, sendRequest] = useChatStream(props.agentId, true);

  const [finished, setFinished] = React.useState(false);

  const [chatStartTs, setChatStartTs] = React.useState(null);

  React.useEffect(() => {
    setChatStartTs(null);
  }, [props.path]);

  const [
    userTask,
    loadingUserTask,
    error,
    snap,
  ] = useDocumentFirestore(props.path);

  const [collapsed, setCollapsed] = React.useState(props.collapsed);

  const toggleCollapse = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const deleteTask = React.useCallback(() => {
    return deletePath(props.path)
    .then(() => setFinished(true));
  }, [props.path]);

  const addItemToTask = React.useCallback((itemPath) => {
    console.log("addItemToTask:", itemPath);
    return props.path &&
      updatePath(props.path, {
        itemPaths: Fields.arrayUnion(itemPath),
      });
  }, [props.path]);

  React.useEffect(() => {
    finished ?
      props.onRemoveContextPath(props.path) :
      props.onAddContextPath(props.path);
  }, [finished]);

  React.useEffect(() => {
    if (loadingUserTask) return;

    if (userTask && !chatStartTs) {
      setChatStartTs(now());
      console.log(">>>>>>>>>TaskManager:sendRequest:task:", JSON.stringify(userTask, null, 2));
      return sendRequest(
        userTask.steps ?
          "lets continue" :
          "Lets create a plan for this task",
        [props.path],
        3,
        props.path + `/${props.agentId}`,
      );
    }
  }, [loadingUserTask, userTask, chatStartTs]);

  React.useEffect(() => {
    !loadingUserTask &&
    !userTask &&
    console.log("TaskManager:useEffect:setFinished:true");

    !loadingUserTask &&
    !userTask &&
    setFinished(true);
  }, [loadingUserTask, userTask]);

  React.useEffect(() => {
    finished &&
    dispatch(aiActions.clearActiveUserTask());
  }, [finished]);

  const clearActiveEmailDraft = React.useCallback(() => {
    console.log("userTaskProgressManager:clearActiveEmailDraft");
    // dispatch(
    //   aiActions
    //   .clearActiveEmailDraft(),
    // );
  }, []);

  if (loadingUserTask) {
    return null;
  }

  return <UserTaskProgress
    collapsed={!!collapsed}
    userTask={userTask}
    finished={finished}
    toggleCollapse={toggleCollapse}
    onClickDelete={deleteTask}
    onAddContextPath={props.onAddContextPath}
    onRemoveContextPath={props.onRemoveContextPath}
    onClearEmailDraftAction={clearActiveEmailDraft}
    path={props.path}
  />;
}

export default UserTaskProgressManager;
