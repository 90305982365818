/** @namespace chatStream */
import React from "react";
import now from "lodash/now";
import {api} from "@web/lib/api";
import {AppContext, LinesContext} from "./context";
import debounce from "lodash/debounce";
import {useDispatch} from "react-redux";
import {useLocation} from "wouter";

const url = (location, hotlineContext) => {
  switch (true) {
    case location === "/line":
      return process.env.NODE_ENV === "production" ?
        "https://line-v1-7zujc5azra-uw.a.run.app/assistant" :
        "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant";
    // case location === "/line-onboarding":
    //   return process.env.NODE_ENV === "production" ?
    //     "https://line-v1-7zujc5azra-uw.a.run.app/assistant" :
    //     "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant";
    // case hotline && (location === `/line/${hotline}`):
    //   return process.env.NODE_ENV === "production" ?
    //     `https://line-v1-7zujc5azra-uw.a.run.app/messenger/${hotline}` :
    //     `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/messenger/${hotline}`;
    case location === "/pro":
      return process.env.NODE_ENV === "production" ?
        "https://ai-chat-7zujc5azra-uw.a.run.app" :
        "http://127.0.0.1:5001/admin-85663/us-west1/ai-chat";
    // case location === "/pro-onboarding":
    //   return process.env.NODE_ENV === "production" ?
    //     "https://ai-chat-7zujc5azra-uw.a.run.app" :
    //     "http://127.0.0.1:5001/admin-85663/us-west1/ai-chat";
    default:
      return process.env.NODE_ENV === "production" ?
        `https://line-v1-7zujc5azra-uw.a.run.app/messenger/${hotlineContext}` :
        `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/messenger/${hotlineContext}`;
  }
};

const debouncedSendRequest = debounce((location, {hotlineContext, agentId, userId, text, history, onData, onError, path, isRetry = false, contextPaths = []}) => {
  const ts = now();
  const prompt = {
    text,
    agentId,
    userId,
    createdTs: ts,
    updatedTs: ts,
    history,
    path,
    isRetry,
    contextPaths,
    hotlineContext,
  }
  console.log("useChatStream:debouncedSendRequest:", prompt, location);
  try {
    api.STREAM({prompt, url: url(location, hotlineContext)}, onData, onError);
  } catch (error) {
    console.log("Error streaming chat", error);
    onError && onError(error);
  }
}, 500, {leading: false, trailing: true});

/**
 * @function useChatStream
 * @memberof chatStream
 * @param {string} [agentId="ai-assistant"]
 * @param {boolean} [withDispatch]
 * @returns {[models.Answer.Data[], function(text:string, contextPaths:string[]?, history:(models.Chat.Data[]|number)?, collectionPath:string?, isRetry:boolean?):void, Error, boolean, string]}
 */
function useChatStream(agentId, withDispatch) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const [hotlineContext] = React.useContext(LinesContext);
  const [answers, setAnswers] = React.useState([]);
  const [chatPath, setChatPath] = React.useState("");
  const [streamDone, setStreamDone] = React.useState(false);
  const [streamError, setStreamError] = React.useState(null);
  const [location, setLocation] = useLocation();

  const onData = (stream) => {
    setChatPath(stream.path);
    setStreamDone(!!stream.done);
    setAnswers([...stream.chat?.answers]);
    withDispatch &&
    dispatch({
      type: "AI_WRITE_STREAM",
      agentId,
      stream,
      streamDone: streamDone,
    });
  }
  const onError = (error) => {
    setStreamError(error);
  }

  /**
   * @function sendRequest
   * @memberof chatStream
   * @param {string} text
   * @param {string[]} [contextPaths]
   * @param {models.Chat.Data[]|number} [history]
   * @param {string} [collectionPath]
   * @param {boolean} [isRetry]
   * @return {void}
   */
  function sendRequest(text, contextPaths = [], history, collectionPath, isRetry){
    setStreamError(null);
    setStreamDone(false);
    !!text &&
    debouncedSendRequest(location, {
      agentId,
      userId: user.uid,
      text,
      history: history ?? 0,
      contextPaths,
      path: collectionPath,
      hotlineContext,
      isRetry,
      onData,
      onError,
    });
  }

  return [answers, sendRequest, streamError, streamDone, chatPath];
}

export default useChatStream;
