import React from "react";
import PropTypes from "prop-types";
// import ActionListItem from "./ActionListItem";
import {Button, Divider, List, Space, Tag, Typography} from "antd";
import {useSelector} from "react-redux";
import {EmailIconOutlined, SpamIcon, XIcon} from "@web/assets/icons";
import Icon from "./Icon";
import {Link} from "wouter";

TodosListItem.propTypes = {

};

function TodosListItem({todoId}) {
  const [expand, setExpand] = React.useState(false);
  const todo = useSelector(state => state.Tasks.data[todoId]);
  if (!todo) return null;
  const {actionable} = todo;
  const userActions = todo?.["user-actions"] || {};
  const {todoText, todoDue, todoStatus} = todo?.["router-todos"] || {};
  const text = todoText || actionable?.text || userActions?.text;
  const collapsibleClasses = expand ? 'space-col space-start' : 'space-center-y';

  return <List.Item
    onClick={() => setExpand(!expand)}
    className={'list-item-expandable' + (expand ? ' expanded': ' collapsed')}
    extra={!expand && <Button ghost><XIcon className="muted-icon" size={18} /></Button>}
    // actions={expand ? [<Button size="middle">Continue</Button>] : null}
  >
    <Space direction="vertical">
      <div className={`space ${collapsibleClasses}`} style={{width: '100%'}}>
        <div className="space space-center-y"><Tag color="blue">New</Tag><span className="muted">Reminder</span></div>
        <span className="muted">{text?.substring(0, expand ? 500 : 80)}</span>
        <span><Typography.Text style={{color: 'orange'}} code>{todoStatus}</Typography.Text>
        est. due <Typography.Text style={{color: 'green'}} code>{todoDue}</Typography.Text></span>
        {expand && <div className="pt-8">
          <Space>
            <Link href="#save">Keep</Link>
            <Link href="#dismiss">Dismiss</Link>
          </Space>
        </div>}
      </div>
    </Space>
  </List.Item>;
}

export default TodosListItem;