import React from 'react';
import PropTypes from 'prop-types';
import backgroundActions from "@web/redux/background/actions";
import {useDispatch} from "react-redux";
import {Tooltip} from "antd";
import appActions from "@web/redux/app/actions";

VideoLink.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  hideTooltip: PropTypes.bool,
};

function VideoLink(props) {
  const dispatch = useDispatch();
  const startContentVideo = (url) => dispatch(backgroundActions.startContentVideo({url}));
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // startContentVideo(props.url);
    // startContentVideo("/video/about-thescribe-ai.mp4")
    dispatch(appActions.openVideoModal({url: props.url}));
    return false;
  }
  return props.hideTooltip ?
    <label className={props.className || "ai-text"} onClick={onClick}>{props.children}</label> :
    <Tooltip title="Video">
      <label className={props.className || "ai-text"} onClick={onClick}>{props.children}</label>
    </Tooltip>
}

export default VideoLink;
