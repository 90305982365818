import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Steps, Typography} from "antd";
import {DownArrowOutlined} from "@web/assets/icons";
import Toggle from "./Toggle";
import MarkdownTimeline from "./MarkdownTimeline";
import {AppContext} from "@web/hooks/context";
import StatusActions from "./StatusActions";
// import DraftEditorManager from "./DraftEditorManager";

UserTaskProgress.propTypes = {
  collapsed: PropTypes.bool,
  userTask: PropTypes.object.isRequired,
  agentAnswer: PropTypes.object,
  finished: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onAddContextPath: PropTypes.func.isRequired,
  onRemoveContextPath: PropTypes.func.isRequired,
  onClearEmailDraftAction: PropTypes.func.isRequired,
};

function UserTaskProgress(props) {
  const [, user] = React.useContext(AppContext);

  if (!props.userTask) {
    return null;
  }
  const sortedSteps = Object.entries(props.userTask?.steps || {}).sort((a, b) => a[1].sequenceNumber - b[1].sequenceNumber);
  const numSteps = sortedSteps.length || false;

  return <Card
    bordered={false}
    className="card-rounded card-simple extra-round"
    title={<div className="space space-col">
      <label className="font-lg bolded">{props.userTask?.goal || "Task"}</label>
      <span className="ai-text font-thin">{props.userTask.summary}</span>
    </div>}
    extra={<Button ghost onClick={props.toggleCollapse}><DownArrowOutlined /></Button>}
  >
    {/*<Typography.Text>{props.userTask.summary}</Typography.Text>*/}
    {!props.collapsed ?
      <Steps
        direction="vertical"
        // progressDot
        current={sortedSteps.findIndex(([, taskStep]) => !taskStep.isCompleted)}
        items={sortedSteps.map(([stepId, taskStep]) => {
          const status = taskStep.isCompleted ? "Completed" : taskStep.isSkipped ? "Skipped" : "In Progress";
          return {
            title: <span className={`bolded font-lg ${taskStep.isSkipped ? "muted" : taskStep.isCompleted ? "" : ""}`}>{taskStep.step}</span>,
            subTitle: taskStep.stepNotes && status,
            description: <div className="space space-col">
              {
                // <Toggle
                //   trigger={<label className="green-color">{taskStep.contextList?.length || false} Reference{taskStep.contextList?.length > 1 && "s"}</label>}
                //   showExpandCaret={true}
                //   hideTriggerOnExpand={true}
                // >
                <MarkdownTimeline
                  markdown={`${taskStep.stepNotes || status}  
                  ${taskStep.contextList?.map?.((listItem) => {
                    return listItem.startsWith("http") ?
                      listItem :
                      `[related](#source-link?path=user/${user.uid}/${listItem})`;
                  }).join("\n") || ""}`}
                  // action={[<StatusActions
                  //   path={props.path}
                  //   initialStatus={""}
                  //   iconClassName="green-icon"
                  //   actions={["remind", "done", "dismiss"]}
                  //   size="middle"
                  // />]}
                />
                // </Toggle>
              }
            </div>,
            status: (taskStep.isCompleted || taskStep.isSkipped) ? "finish" : "process",
          };
        })}
        // items={[
        //   {
        //     title: 'Finished',
        //     description: 'This is a description.',
        //   },
        //   {
        //     title: 'In Progress',
        //     description: 'This is a description.',
        //   },
        //   {
        //     title: 'Waiting',
        //     description: 'This is a description.',
        //   },
        // ]}
      /> : numSteps > 1 && <label className="green-color" onClick={props.toggleCollapse}>{`${numSteps} Steps`}</label>
    }
    {/*<div style={{display: collapsed ? "none" : "block"}}>*/}
    {/*  {props.userTask?.actions?.map((action) => {*/}
    {/*    switch (action.actionComponent) {*/}
    {/*      case "draftEmail": {*/}
    {/*        return <DraftEditorManager*/}
    {/*          agentId={"ai-progress"}*/}
    {/*          draftPath={action.draftPath}*/}
    {/*          onAddContextPath={props.onAddContextPath}*/}
    {/*          onRemoveContextPath={props.onRemoveContextPath}*/}
    {/*          onClearActiveEmailDraft={props.onClearEmailDraftAction}*/}
    {/*        />*/}
    {/*      }*/}
    {/*      default: {*/}
    {/*        return null;*/}
    {/*      }*/}
    {/*    }*/}
    {/*  })}*/}
    {/*</div>*/}
  </Card>
}

export default UserTaskProgress;
