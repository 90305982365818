import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, List, Tag, Typography} from "antd";
import UserAvatar from "./UserAvatar";
import {DownArrowOutlined, FlagIcon} from "@web/assets/icons";
import now from "lodash/now";
import Day from "./Day";
import MarkdownTimeline from "./MarkdownTimeline";
import Toggle from "./Toggle";
import StatusActions from "./StatusActions";
import {useDispatch, useSelector} from "react-redux";
import IconTip from "./IconTip";
import contentActions from "@web/redux/content/actions";
import ContactDetailsListItemMeta from "./ContactDetailsListItemMeta";
import DetailData from "@web/ui/containers/DetailData";
import ContentDetailWrapper from "./ContentDetailWrapper";
import DetailItem from "./timeline/DetailItem";
import dayjs from "dayjs";
import RecommendedTodo from "./RecommendedTodo";
import useItemVisibilityLogger from "@web/hooks/useItemVisibilityLogger";

ContactDetailsListItem.propTypes = {
  contextPath: PropTypes.string.isRequired,
  itemPath: PropTypes.string.isRequired,
  primary: PropTypes.bool,
};

function ContactDetailsListItem(props) {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = React.useState(false);
  const [ref] = useItemVisibilityLogger(props.itemPath);

  /** detect when the component is unmounted */
  React.useEffect(() => {
    return () => {
      dispatch({
        type: "LOG_ITEM_VISIBILITY",
        payload: {inView: false, path: props.itemPath}});
    };
  }, [props.itemPath]);

  const eventsByActivity = useSelector((state) => {
    return state.Content.activity?.[props.contextPath]?.eventsByItem?.[props.itemPath];
  });

  const event = useSelector((state) =>
    state.Content.events[eventsByActivity[0]]);

  const onClickItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDetails(!showDetails);

    !showDetails &&
    dispatch(contentActions.loadContext(event.itemPath));
  }

  return <List.Item
    ref={ref}
    key={`list-item-${props.itemPath}`}
    // actions={[
    //   <IconTip
    //     key="details"
    //     title="Details"
    //     placement="right"
    //   >
    //     <Button
    //       ghost
    //       onClick={onClickItem}
    //     >
    //       <DownArrowOutlined
    //         key="details"
    //         className="green-icon"
    //       />
    //     </Button>
    //   </IconTip>,
    // ]}
  >
    <div className="space space-col" style={{width: '100%'}}>
      {eventsByActivity.map((eventId) => {
        return <DetailItem
          key={eventId}
          path={event.itemPath}
          contextPath={props.contextPath}
          primary={props.primary}
          onClick={onClickItem}
          // activityPath={props.activityPath}
          // dateKey={dayjs(event.date).format("YYYY-MM-DD")}
          collapsed={false}
        />
        // return showDetails ?
        //   <DetailItem
        //     key={eventId}
        //     path={event.itemPath}
        //     contextPath={props.path}
        //     primary={props.primary}
        //     // activityPath={props.activityPath}
        //     // dateKey={dayjs(event.date).format("YYYY-MM-DD")}
        //     collapsed={false}
        //   /> :
        //   <React.Fragment key={eventId}>
        //     {/*<ContactDetailsListItemMeta*/}
        //     {/*  key={eventId}*/}
        //     {/*  path={props.path}*/}
        //     {/*  // activityPath={props.activityPath}*/}
        //     {/*  eventId={eventId}*/}
        //     {/*  // displayName={props.displayName}*/}
        //     {/*  // name={props.name}*/}
        //     {/*  // email={props.email}*/}
        //     {/*  // contactIcon={props.contactIcon}*/}
        //     {/*  primary={props.primary}*/}
        //     {/*  onClick={onClickItem}*/}
        //     {/*/>*/}
        //     <DetailItem
        //       key={eventId}
        //       path={event.itemPath}
        //       contextPath={props.path}
        //       primary={props.primary}
        //       // activityPath={props.activityPath}
        //       // dateKey={dayjs(event.date).format("YYYY-MM-DD")}
        //       collapsed={false}
        //     />
        //     {/*<div style={{marginLeft: '60px', marginBottom: '16px'}}>*/}
        //     {/*  <RecommendedTodo*/}
        //     {/*    key={eventId}*/}
        //     {/*    eventId={eventId}*/}
        //     {/*    activityPath={props.activityPath}*/}
        //     {/*    defaultExpanded={props.primary}*/}
        //     {/*    primary={props.primary}*/}
        //     {/*  />*/}
        //     {/*  /!*{props.activityPath.split("/").pop().split("_").pop()}*!/*/}
        //     {/*</div>*/}
        //   </React.Fragment>
      })
      }
      {/*{showDetails &&*/}
      {/*  <DetailItem*/}
      {/*    path={event.itemPath}*/}
      {/*    dateKey={dayjs(event.date).format("YYYY-MM-DD")}*/}
      {/*    collapsed={false}*/}
      {/*  />*/}
      {/*}*/}
    </div>
  </List.Item>
}

export default ContactDetailsListItem;
