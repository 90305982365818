import React from "react";
import {AppContext} from "@web/hooks/context";
import {Redirect} from "wouter";
// import WithProOnboarding from "@web/ui/containers/WithProOnboarding";
import WithLineOnboarding from "./WithLineOnboarding";

ConsoleRouter.propTypes = {};

function ConsoleRouter() {
  const [,, claims] = React.useContext(AppContext);

  return claims.data?.subscribed ?
    <WithLineOnboarding>
      {/*<WithProOnboarding>*/}
        <Redirect to="/pro" />
      {/*</WithProOnboarding>*/}
    </WithLineOnboarding> :
    <WithLineOnboarding>
      <Redirect to="/line" />
    </WithLineOnboarding>;
}

export default ConsoleRouter;
