import {useEffect, useState} from 'react'

export const loadFromStorage = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveToStorage = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};


export default function useLocalStorage(key) {
  const [value, setValue] = useState(loadFromStorage(key))
  useEffect(() => {
    setValue(loadFromStorage(key))
  }, [key])
  return [value, (newValue) => {
    saveToStorage(key, newValue)
    setValue(newValue)
  }]
}
