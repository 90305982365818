import React, {useContext} from "react";
import {AppContext} from "@web/hooks/context";
// import LineReservationManager from "@ai-antd/components/LineReservationManager";
import {Redirect} from "wouter";
import {useSelector} from "react-redux";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";

const WithLineOnboarding = (props) => {
  const [, user, claims] = useContext(AppContext);
  const [assistantLine, loadingAssistant] = useDocumentFirestore(user.uid && ["user", user.uid, "lines", "assistant"]);
  const assistantName = useSelector((state) => state.Lines.data?.assistant?.assistantName);
  console.log("WithLineOnboarding", {assistantLine, loadingAssistant});

  if (!user) {
    return null;
  }
  if (!claims?.data) {
    return null;
  }
  if (loadingAssistant) {
    return null;
  }

  return (assistantLine?.assistantName || assistantName) ?
    props.children :
    <Redirect to="/start" />;
}

export default WithLineOnboarding
