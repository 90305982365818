const actions = {
  SYNC_LINES: "SYNC_LINES",
  LOAD_LINES_SUCCESS: "LOAD_LINES_SUCCESS",
  SYNC_LINES_SUCCESS: "SYNC_LINES_SUCCESS",
  SYNC_STREAMS: "SYNC_STREAMS",
  LOAD_STREAMS_SUCCESS: "LOAD_STREAMS_SUCCESS",
  SYNC_STREAMS_SUCCESS: "SYNC_STREAMS_SUCCESS",
  // SYNC_MAIL_SUCCESS: "SYNC_MAIL_SUCCESS",
  // PAGE_MAIL: "PAGE_MAIL",
  // PAGE_MAIL_SUCCESS: "PAGE_MAIL_SUCCESS",
  // SEARCH_MAIL: "SEARCH_MAIL",
  // SEARCH_MAIL_SUCCESS: "SEARCH_MAIL_SUCCESS",
  // syncLines: () => {
  //   return (dispatch) => {
  //     dispatch({
  //       type: actions.SYNC_LINES,
  //     });
  //   };
  // },
  // pageNext: (memberUserId) => {
  //   return (dispatch, getState) => {
  //     const params = getState().Email?.pageParams;
  //     console.log("pageNext", {memberUserId, params});
  //     dispatch({
  //       type: actions.PAGE_MAIL,
  //       memberUserId,
  //       params,
  //     });
  //   };
  // },
  // searchMail: () => {
  //   return (dispatch, getState) => {
  //     console.log("searchMail");
  //     const params = getState().Search?.pageParams;
  //     dispatch({
  //       type: actions.SEARCH_MAIL,
  //       params: {},
  //     });
  //   };
  // },
};

export default actions;
