import React from "react";
import PropTypes from "prop-types";
import {RightArrowOutlined} from "@web/assets/icons";
import {Button} from "antd";

InputSaveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
};

function InputSaveButton(props) {
  return props.show ?
    <Button
      size="large"
      ghost
      className="icon-btn icon-right"
      style={{position: "absolute", right: 5, top: 5}}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <RightArrowOutlined size={24} className="green-icon" />
    </Button> :
    null;
}

export default InputSaveButton;
