import React from "react";
import {DatePicker, Space} from "antd";
// import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import {AppContext} from "@web/hooks/context";
import dayjs from "dayjs";
import {CheckIcon} from "@web/assets/icons";
import {notifyAndTrack} from "./notification";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {updatePath} from "@web/lib/firestore.db";

export default function MemoryOriginDate() {
  const MAX = 3;
  const [,user] = React.useContext(AppContext);
  const [displayDiff, setDisplayDiff] = React.useState("");
  const [userData,,, userSnapshot] = useDocumentFirestore(["user", user.uid].join("/"));
  const customFormat = (value) => {
    const diff = Math.abs(value.diff(dayjs(), "months"));
    return `${diff} Months`;
  }

  const onDateChange = (value, dateString) => {
    if (!value) return;
    setDisplayDiff(value.format("MMM DD YYYY"));
    updatePath(userSnapshot.ref.path, {originTs: value.valueOf()})
    .then(() => notifyAndTrack.success("success", "Memory Origin Date Updated", value.valueOf()));
  }

  if (!user) {
    return null;
  }
  return <div className="space space-center-y">
    <DatePicker
      size="large"
      allowClear={false}
      disabledDate={(value) =>
        value.diff(dayjs(), "months") < -MAX ||
        value.diff(dayjs(), "months") > -3
    }
      onChange={onDateChange}
      format={customFormat}
      picker="month"
      defaultValue={dayjs(userData.originTs)}
    />
    &nbsp;
    {!!displayDiff && <div className="space space-center-y"><CheckIcon className="gold-icon"/> <span
      className="ai-text">Starting {displayDiff}</span></div>}
    {/*<LineConnector style={{maxWidth: 240}} /><h4 className="muted"></h4>*/}
  </div>
}
