import React from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

DashboardCenterRow.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  children: PropTypes.node,
};

function DashboardCenterRow(props) {
  return <Row className="w-full">
    <Col xs={0} sm={0} md={0} lg={1} xl={6} xxl={6}>{props.left || null}</Col>
    <Col xs={24} sm={24} md={24} lg={22} xl={12} xxl={12} className="overscroll-contain">
      {props.children}
    </Col>
    <Col xs={0} sm={0} md={0} lg={1} xl={6} xxl={6}>{props.right || null}</Col>
  </Row>
}

export default DashboardCenterRow;
