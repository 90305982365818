import {all, takeLeading, call, put, delay} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {buildQuery, getDocuments} from "@web/lib/firestore.db";
import {toFirestoreQueryItems, firestore as channel} from "../channels";

/**
 * @summary Build and return a query
 * @return {Query<DocumentData>}
 */
const getLinesQuery = () => {
  return buildQuery(
    ["user", auth.currentUser.uid, "lines"],
      {},
    {});
};

/**
 * @summary Build and return a query
 * @return {Query<DocumentData>}
 */
const getStreamsQuery = (limit) => {
  return buildQuery(
    ["user", auth.currentUser.uid, "audio-streams"],
    {},
    {order: [['date', 'desc']], limit});
};

/**
 * @summary sync streams
 * @generator
 * @return {Generator<*, void, *>}
 */
function* syncStreams() {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getStreamsQuery(1),
    success: actions.SYNC_STREAMS_SUCCESS,
  }];

  yield call(channel, listenTo);
}

/**
 * @summary sync email
 * @generator
 * @return {Generator<*, void, *>}
 */
function* syncLines() {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getLinesQuery(),
    success: actions.SYNC_LINES_SUCCESS,
  }];

  yield call(channel, listenTo);
}

/**
 * Load and sync lines
 * @return {Generator<*, void, *>}
 */
function* loadLines() {
  if (!auth.currentUser?.uid) return;
  const query = getLinesQuery();
  const querySnapshot = yield call(getDocuments, query);
  const lines = toFirestoreQueryItems(querySnapshot);

  yield put({
    type: actions.LOAD_LINES_SUCCESS,
    payload: {lines},
  });

  delay(5000);
  yield call(syncLines);
}


/**
 * Load and sync streams
 * @return {Generator<*, void, *>}
 */
function* loadStreams() {
  if (!auth.currentUser?.uid) return;

  const query = getStreamsQuery(3);
  const querySnapshot = yield call(getDocuments, query);

  yield put({
    type: actions.LOAD_STREAMS_SUCCESS,
    payload: {streams: toFirestoreQueryItems(querySnapshot)},
  });

  delay(5000);
  yield call(syncStreams);
}

export default function* rootSaga() {
  yield all([
    takeLeading(actions.SYNC_LINES, loadLines),
    takeLeading(actions.SYNC_STREAMS, loadStreams),
  ]);
}
