import React, {useCallback} from "react";
import {List} from "antd";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
import ListItemActive from "./ListItemActive";
import {ClockIcon, InboxIcon, TodoIcon} from "@web/assets/icons";

function TaskListItem(props) {
  const dispatch = useDispatch();
  const isMobile = true;
  const task = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const isStarted = task?.steps &&
    Object.values(task.steps).some((step) => {
      console.log("TaskListItem:step", step);
      return !!step.completionTs;
    });

  const onClick = useCallback((e) => {
    const data = {path: props.path, task};
    if (props.onClick) return props.onClick(data, e);

    dispatch(appActions.setActiveDetail("progress", data, true));
  }, [dispatch,props]);

  if (!task) {
    return null
  }

  return <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[]}
    onClick={onClick}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={task.isCompleted ? <TodoIcon className="green-icon" /> : isStarted ? <ClockIcon style={{color: "orange"}} /> : <InboxIcon />}
          userId={task.assignedTo?.userId || task.userId}
        />}
      title={
        <div className="space space-center-y relative" style={{width: "100%"}}>
          <h6 className="capitalize line-clamp-2" style={{width: "78%"}}>
            {task.summary}
          </h6>
          <span key="ts" className="muted font-sm absolute" style={{width: "50px", right: 6, top: 4, textAlign: "left"}}>
            <Day ts={task.updatedTs} format="MMM D" />
          </span>
        </div>}
  />
  <ListItemActive
    path={props.path}
    offset={'5px'}
  />
  </List.Item>
}

export default TaskListItem;
