import React from 'react';
import {useInView} from 'react-intersection-observer';
import PropTypes from "prop-types";

WithActiveContentObserver.propTypes = {
  children: PropTypes.node.isRequired,
  onInView: PropTypes.func.isRequired,
  threshold: PropTypes.number,
  delay: PropTypes.number,
  wrapperProps: PropTypes.object,
};

function WithActiveContentObserver(props) {
  const [ref, isInView, entry] = useInView({
    threshold: props.threshold || 0.5, delay: props.delay || 200,
  });

  React.useEffect(() => {
    props.onInView(isInView);
  }, [isInView]);

  return <div ref={ref} style={{width: '100%'}} {...props.wrapperProps || null}>
    {props.children}
  </div>;
}

export default WithActiveContentObserver;
