import React, {useContext} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {WindowContext} from "@web/hooks/context";
import Pager from "@web/ui/containers/Pager";
import Loading from "./Loading";
import LineConnector from "./LineConnector";
import PaymentListItem from "./PaymentListItem";
import {Alert, Card, Empty, List, Tabs} from "antd";

SubsList.propTypes = {

};

SubsList.propTypes = {
  list: PropTypes.array,
  error: PropTypes.object,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

function SubsList(props) {
  const activeServiceId = useSelector((state) => state.App.activeServiceId);
  // const assistant = useSelector((state) => state.Lines.data.assistant);
  // const client = useSelector((state) => state.Lines.data.client);
  // const messenger = useSelector((state) => state.Lines.data.messenger);
  const [,, height] = useContext(WindowContext);

  if (props.error) {
    return <Alert
      message={props.error.message}
      type="error"
      showIcon
      closable
    />;
  }

  return <div
    id="services-payments-scroll-target"
    style={{height: height - 60, overflow: 'auto', display: 'flex', flexDirection: 'column'}}
  >
    <Pager
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-payments-scroll-target"
    >
      {/*<Tabs*/}
      {/*  centered={true}*/}
      {/*  tabPosition="top"*/}
      {/*  items={[*/}
      {/*    {*/}
      {/*      key: "all",*/}
      {/*      label: "All",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      key: "paid",*/}
      {/*      label: "Paid",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      key: "unpaid",*/}
      {/*      label: "Unpaid",*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
      <List
        style={{marginTop: '20px'}}
        // header={<h6 style={{paddingLeft: '12px'}} className="capitalize">{activeServiceId}</h6>}
        className="services-payments-list list-empty-no-padding list-item-lite-divider"
        dataSource={props.list}
        // renderItem={([path, date]) =>
        //   <MarkdownListItem
        //     key={path}
        //     path={path}
        //     date={date}
        //     dataKey="payment"
        //   />
        // }
        renderItem={([path, date]) =>
          <PaymentListItem key={path} path={path} date={date} />
        }
        // loading={props.dataLoading}
        // pagination={{
        //   pageSize: 30,
        //   showSizeChanger: false,
        // }}
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
            {/*<Card className="card-simple card-rounded card-no-header" bordered={false}>*/}
            {/*  No lines to show*/}
            {/*</Card>*/}
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default SubsList;
