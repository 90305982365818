import React, {useContext} from "react";
import {Alert, Card, Empty, List} from "antd";
import CleanModeButton from "./CleanModeButton";
import PropTypes from "prop-types";
import {ThreadsListItem} from "./ThreadsListItem";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import Loading from "./Loading";
import {WindowContext} from "@web/hooks/context";

ThreadsList.propTypes = {
  list: PropTypes.array,
  hasMore: PropTypes.bool,
  pageNext: PropTypes.func,
  error: PropTypes.object,
}

function ThreadsList(props) {
  const [,, height] = useContext(WindowContext);

  if (props.error) {
    return <Alert message={props.error.message} type="error" showIcon closable />
  }

  return <div
    id="services-threads-scroll-target"
    className="paging-list-wrapper"
    style={{height: height - 60, overflow: 'auto', display: 'flex', flexDirection: 'column'}}
  >
    <Pager
      loader={<Loading inline />}
      length={props.list?.length ?? 0}
      hasMore={props.hasMore}
      endMessage={!!props.list?.length &&
        <div className="space space-center-y" style={{position: "relative", top: "6px"}}><h6
          className="muted">End</h6>&nbsp;<LineConnector/>
        </div>}
      pageNext={props.pageNext}
      scrollContainerId="services-threads-scroll-target"
    >
      <List
        style={{marginLeft: '10px'}}
        header={
          <div className="space space-between space-center-y">
            <h6>Email</h6>
            <CleanModeButton/>
          </div>
        }
        className="list-item-no-divider list-empty-no-padding"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <ThreadsListItem
            key={path}
            path={path}
            date={date}
          />
        }
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
            <Card className="card-simple card-rounded card-no-header" bordered={false}>
            </Card>
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default ThreadsList;
