import React from 'react'
import {Button, Divider, Typography} from "antd";
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@web/lib/firebase'
import EmailAuthPage from './EmailAuthPage'
import Loading from '@ai-antd/components/Loading'
import GoogleAuthButton from '@ai-antd/components/GoogleAuthButton'
import AuthLayoutWrapper from '@ai-antd/components/AuthLayoutWrapper'
import MicrosoftAuthButton from '@ai-antd/components/MicrosoftAuthButton'
import {Link, Redirect} from "wouter";
import PropTypes from "prop-types";
import HotClick from "@web/ui/containers/HotClick";

const fullWidthStyle = {width: '100%'}

Login.propTypes = {
  title: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export function Login({title = "A new way to work.", subtitle = "Login", providers = [], redirectTo = '/'}) {
  const [user, loadingUser] = useAuthState(auth)

  if (loadingUser) return <Loading />
  if (user && !user.isAnonymous) {
    return <Redirect to={redirectTo} />
  }
  // if (isLoggedIn) {
  //   if (redirectCallback) return redirectCallback(user)
  //   return <Redirect to={redirectTo}/>;
  // }

  return <AuthLayoutWrapper hideLinks>
    <Typography.Title level={2} style={{margin: '20px 40px 0'}}>{title}</Typography.Title>
    <div className="space-col center middle" style={fullWidthStyle}>
      <EmailAuthPage
        intent="sign-in"
        title={subtitle}
        redirectTo={redirectTo}
        hideMoreOptions
        actions={
          <Button
            shape="round"
            size="large"
            className="icon-btn"
            block={false}
            type="ghost"
          >
          <HotClick>
            <Link to="/phone/sign-in" style={{color: 'green', display: 'inline-block', textDecoration: 'none'}}>Use phone login</Link>
          </HotClick>
          </Button>
        }
      />
      <Divider />
      <div className="space space-col gap-30">
        {providers.indexOf("google.com") >= 0 ? <GoogleAuthButton block>Login with Google</GoogleAuthButton> : null}
        {providers.length > 1 && <Divider className="small-top small-bottom"/>}
        {providers.indexOf("microsoft.com") >= 0 ? <MicrosoftAuthButton block/> : null}
        <HotClick>
          <Link to="/sign-up" style={{color: 'green', marginTop: '10px', display: 'inline-block', textDecoration: 'underline'}}>Create an account</Link>
        </HotClick>
      </div>
      {/*<br />*/}
      {/*<br />*/}
      {/*{providers.indexOf("google.com") >= 0 && <GoogleAuthButton block>Login with Google</GoogleAuthButton>}*/}
      {/*{providers.length > 1 && <Divider className="small-top small-bottom"/>}*/}
      {/*{providers.indexOf("microsoft.com") >= 0 && <MicrosoftAuthButton scopes={['openid']} block/>}*/}
      {/*<HotClick>*/}
      {/*  <Link to="/phone-verify" style={{color: 'green', marginTop: '10px', display: 'inline-block', textDecoration: 'underline'}}>Phone login</Link>*/}
      {/*</HotClick>*/}
    </div>
  </AuthLayoutWrapper>
}

export default Login
