import React from "react";
import PropTypes from "prop-types";
import {Button, Tag, Tooltip, Typography} from "antd";
import {AppContext} from "@web/hooks/context";
import MicrosoftAuthButton from "./MicrosoftAuthButton";
import GoogleAuthButton from "./GoogleAuthButton";
import {getDocument} from "@web/lib/firestore.db";
import {AsanaIcon, DropboxIcon, ExcelIcon, FeatherIcon, LinkedInIcon, PhoneCallIcon} from "@web/assets/icons";
import TrelloAuthButton from "./TrelloAuthButton";
import BatchOperationManager from "@web/ui/containers/BatchOperationManager";
import UploadModal from "./UploadModal";

OauthConnectButton.propTypes = {
  integrationId: PropTypes.string.isRequired,
  integration: PropTypes.object,
  label: PropTypes.string,
};

function OauthConnectButton({integrationId, integration: integrationData, label = "Connect"}) {
  const [, user] = React.useContext(AppContext);
  const [integration, setIntegration] = React.useState(integrationData);
  const state = integrationId + ":" + user.uid;

  React.useEffect(() => {
    if (!integrationId) {
      return;
    }
    if (!integration?.provider) {
      getDocument("app-integrations", integrationId)
      .then((snap) =>
        setIntegration(snap.data()));
    }
  }, [integrationId, integration?.provider]);

  const getButton = () => {
    switch (integration?.provider) {
      case "microsoft.com":
        return <MicrosoftAuthButton
          scopes={integration.scopes}
          size={"default"}
          integration={integration}
          integrationId={integrationId}
        >{label}</MicrosoftAuthButton>;
      case "google.com":
        return <GoogleAuthButton
          scopes={integration.scopes}
          size={"default"}
          integration={integration}
          integrationId={integrationId}
        >{label}</GoogleAuthButton>;
      case "asana.com":
        return <Button disabled>
          <AsanaIcon size={16} style={{marginRight: "10px", verticalAlign: "middle"}}/>
          <Typography.Link href={integration.authorizeUrl + state} style={{color: "#fff"}}>{label}</Typography.Link>
        </Button>;
      case "trello.com":
        return <TrelloAuthButton
          disabled
          integrationId={integrationId}
          integration={integration}
          label={label}
        />;
      case "dropbox.com":
        return <Button disabled>
          <DropboxIcon
            size={16}
            style={{marginRight: "10px", verticalAlign: "middle"}}
          />
          <Typography.Link
            href="#"
            // style={{color: "#fff"}}
          >{label}
          </Typography.Link>
        </Button>;
      case "linkedin.com":
        return <Button disabled>
          <LinkedInIcon size={16} style={{marginRight: "10px", verticalAlign: "middle"}}/>
          <Typography.Link href="#">{label}</Typography.Link>
        </Button>;
      case "excel.com":
        return <Button disabled>
          <ExcelIcon size={16} style={{marginRight: "10px", verticalAlign: "middle"}}/>
          <Typography.Link href="#">
            {label}
          </Typography.Link>
        </Button>;
      case "spokephone.com":
        return <Button disabled>
          <PhoneCallIcon size={16} style={{marginRight: "10px", verticalAlign: "middle"}}/>
          <Typography.Link href="#">
            {label}
          </Typography.Link>
        </Button>;
      case "getline.ai":
        return <Button disabled>
          <FeatherIcon size={16} style={{marginRight: "10px", verticalAlign: "middle"}}/>
          <Typography.Link href="#">Activate</Typography.Link>
        </Button>;
      case "upload.getline.ai":
        return <UploadModal/>;
      default:
        return null;
    }
  };

  return <Tooltip
    title={
    <h6>
      {integration?.description} <Tag>{integration?.provider}</Tag>
    </h6>}
    placement="bottom"
    trigger={["hover"]}
    destroyTooltipOnHide
    overlayStyle={{width: "300px", maxWidth: "300px"}}
  >
    <div>
      <BatchOperationManager>
        {getButton()}
      </BatchOperationManager>
    </div>
  </Tooltip>;
}

export default OauthConnectButton;
