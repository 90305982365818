import React from "react";
import {Button} from "antd";
import {AppContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import {notifyAndTrack} from "./notification";
import {DownloadOutlineIcon} from "@web/assets/icons";

RefreshSourceButton.propTypes = {
  loader: PropTypes.string.isRequired,
  data: PropTypes.object,
  onLoadError: PropTypes.func,
  onStart: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonProps: PropTypes.object,
  disabled: PropTypes.bool,
};

function RefreshSourceButton({loader, data = {}, onLoadError, onStart, text, buttonProps = null, ...props}) {
  const [_, user] = React.useContext(AppContext);
  const [callLoadFn, runningFn, errorFn] = useFunctionsCallable(loader);
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user || user?.isAnonymous) {
      return notifyAndTrack.warn("Please login", "You must be logged in to refresh your data");
    }
    callLoadFn(data)
      .then((response) => {
        if (!response) {
          notifyAndTrack.error("Error", "No response from server");
          return;
        }
        const {data} = response || {};
        console.log("useFunctionsCallable result", data);
        if (data?.error) {
          onLoadError && onLoadError(data);
        }
        onStart && onStart(data);
      })
      .catch((error) => {
        console.log("useFunctionsCallable error", error);
        if (onLoadError) onLoadError(error);
        onStart && onStart(error);
      });
  };

  return <Button
    className="icon-btn green-color"
    disabled={runningFn || !!props.disabled}
    onClick={onClick}
    shape={"round"}
    {...buttonProps}
  >
    <DownloadOutlineIcon size={20} /><span> {text || "Sync"}</span>
  </Button>;
}

export default RefreshSourceButton;
