import React from "react";
import PropTypes from "prop-types";
import {AppContext} from "@web/hooks/context";
import PhoneAuth from "./PhoneAuth";
import EmailAuthPage from "@ai-antd/page/EmailAuthPage";
import useLocalStorage from "@web/hooks/useLocalStorage";

LineLogin.propTypes = {
  line: PropTypes.string,
  children: PropTypes.node,
};

function LineLogin(props) {
  const [, user] = React.useContext(AppContext);
  const [phoneVerified] = useLocalStorage("phone-verified");

  console.log("phoneVerified", phoneVerified);

  if (user?.uid && !user?.isAnonymous) {
    return props.children;
  }

  return <div  className="space space-center-y space-center-x" style={{width: "100%", maxWidth: 600}}>
    {phoneVerified || user?.phoneNumber ?
      <PhoneAuth intent="sign-in" /> :
      <EmailAuthPage intent="sign-in" title="Login" />
    }
  </div>
}

export default LineLogin;
