import React, {useContext} from "react";
import {AppContext} from "@web/hooks/context";
// import LineReservationManager from "@ai-antd/components/LineReservationManager";
import Splash from "@ai-antd/components/Splash";

const RequireLine = (props) => {
  const [, user, claims] = useContext(AppContext)

  if (!user) return null;

  return claims?.data?._h ?
    props.children :
    <Splash />;
}

export default RequireLine
