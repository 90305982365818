import actions from "./actions";
import uniq from "lodash/uniq.js";
import uniqBy from "lodash/uniqBy.js";
// import uniq from "lodash/uniq.js";
// import uniqBy from "lodash/uniqBy.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  lines: {
    all: [],
    items: [],
  },
  streams: {
    data: {},
    list: [],
    items: [],
  },
};
// /**
//  * @summary Merge state
//  * @param {object} groupState
//  * @param {object[]} list
//  * @return {*&{data: (*)}}
//  */
// const mergeGroup = (groupState = {}, list = []) => {
//   return {
//     all: uniq([...(groupState.all || []), ...list]),
//   };
// };
// const groupDocuments = (state, {data, list}) => {
//   return {
//     all: [...(state.all || []), ...list],
//     ...(reduce(data, ($acc, {messageId, threadId}, $documentId) => {
//       $acc[messageId] = $acc[messageId] || [];
//       $acc[messageId].push($documentId);
//       $acc[threadId] = $acc[threadId] || [];
//       $acc[threadId].push($documentId);
//       return $acc;
//     }, {}))
//   }
// }
// const mergeNetwork = (contactsState = {}, contactsData = {}, contactsList = [], threadsData = {}, summaryData = {}) => {
//   const idLookup = reduce(contactsData, ($acc, contact, contactId) => {
//     $acc[contact.email] = contactId;
//     return $acc;
//   }, {});
//   return {
//     idLookup,
//     all: uniq([...(contactsState.all || []), ...contactsList]),
//     ...(reduce(threadsData, ($acc, thread, threadId) => {
//       const {email: fromEmail} = threadsData[threadId]?.latest?.message?.from || {};
//       const contactId = idLookup[fromEmail];
//       const messageId = threadsData[threadId]?.newMessageIds?.[0];
//       if (summaryData[messageId]) {
//         $acc.summary[contactId] = $acc.summary[contactId] || [];
//         $acc.summary[contactId].push(messageId);
//       }
//       return $acc;
//     }, {summary: {}})),
//   };
// };

/**
 * @summary Reducer for feeds
 * @param {object} state
 * @param {string} type
 * @param {{lines:FirestoreQueryItems, streams:FirestoreQueryItems}} payload
 * @return {*}
 */
export default function reducer(state = initState, {type, payload, ...rest}) {
  switch (type) {
    case actions.SYNC_LINES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case actions.LOAD_LINES_SUCCESS:
      return {
        ...state,
        ...payload.lines,
      };
    case actions.SYNC_STREAMS_SUCCESS:
      return {
        ...state,
        streams: {
          data: {
            ...state.streams.data || null,
            ...payload.data || null,
          },
          list: uniq([...payload.list, ...state.streams.list]),
          items: uniqBy([ ...payload.items, ...state.streams.items], "path"),
        },
      };
    case actions.LOAD_STREAMS_SUCCESS:
      // console.log("LOAD_STREAMS_SUCCESS:", payload);
      return {
        ...state,
        streams: payload.streams,
      };
    default:
      return state;
  }
}
