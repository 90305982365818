import React from "react";
import useQuerystring from "@web/hooks/useQuerystring";
import AuthSuccess from "@ai-antd/components/AuthSuccess";

HomeMiddleware.propTypes = {};

function HomeMiddleware(props) {
  const queryString = useQuerystring();

  if (queryString["auth-success"]) {
    return <AuthSuccess />
  }
  return props.children;
}

export default HomeMiddleware;
