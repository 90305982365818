import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Divider, Row, Typography} from "antd";
import StatusActions from "./StatusActions";
import MarkdownTimeline from "./MarkdownTimeline";
import appActions from "@web/redux/app/actions";
import {useDispatch} from "react-redux";
import {SpaceBarIcon} from "@web/assets/icons";
import dayjs from "dayjs";
import {DetailContext, WindowContext} from "@web/hooks/context";

ItemActivityCard.propTypes = {
  event: PropTypes.object,
  itemPath: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  hasTodo: PropTypes.bool,
  dueDate: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.string,
  showItemPreview: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  transparent: PropTypes.bool,
};

function ItemActivityCard(props) {
  const [isMobile, w, h] = React.useContext(WindowContext);
  const detailContext = React.useContext(DetailContext);
  React.useEffect(() => {
    detailContext?.onSetContextPaths &&
    detailContext?.onSetContextPaths([props.itemPath]);
  }, [props.itemPath]);

  return <Card
        hoverable={false}
        bordered={false}
        className={`card-simple card-rounded${props.transparent ? ' card-transparent' : ''}`}
        // style={{height: "600px", maxHeight: "600px"}}
      >
        <div className="space space-col space-center space-center-y space-between">
          <div
            // style={{padding: props.title?.length < 300 ? '40px 80px 0 60px' : '0 60px 0 10px'}}
          >
            <MarkdownTimeline
              markdown={props.title}
              components={{
                p: (props) => {
                  return <Typography.Text
                    className="ai-text"
                    // style={{fontSize: '40px', lineHeight: '1.4em', marginBottom: '15px'}}
                  >{props.children}</Typography.Text>
                }}}
            />
            {/*{!!props.subtitle &&*/}
            {/*  <MarkdownTimeline markdown={props.subtitle?.substring(0, 500)} components={{*/}
            {/*    p: (props) => {*/}
            {/*      return <Typography.Text*/}
            {/*        className="ai-text"*/}
            {/*        // style={{fontSize: '26px', lineHeight: '1.45em'}}*/}
            {/*      >{props.children}</Typography.Text>*/}
            {/*    }}}*/}
            {/*  />}*/}
            {/*<Typography.Title*/}
            {/*  level={(event.update?.summary || event.summary).length < 300 ? 1 : 2}*/}
            {/*  className="ai-text"*/}
            {/*>{event.update?.summary || event.summary}*/}
            {/*</Typography.Title>*/}
            <br />
            <div className="space space-center-y">
            {/*  <span*/}
            {/*    className="icon-btn icon-left"*/}
            {/*    onClick={props.onClick}*/}
            {/*  >*/}
            {/*  <SpaceBarIcon size={50}/>*/}
            {/*</span>*/}
              {props.hasTodo && <h6
                // style={{position: "absolute", bottom: "30px", left: "0", right: "40px", paddingTop: "6px"}}
              >
                <div className="space space-center-y space-between">
                  <StatusActions
                    path={props.itemPath}
                    itemId={props.itemPath}
                    initialStatus={props.status}
                    iconClassName="green-icon"
                    actions={['done', 'dismiss']}
                  />
                </div>
              </h6>}
              {/*{!!props.dueDate && <span>Due {dayjs(props.dueDate).format('M D')}</span>}*/}
            </div>
          </div>
          {/*<span>{(event.updates?.activitySummary || event.summary).length}</span>*/}
          {/*<Space>{event.labels?.map((label, i) => <Tag key={i}><h4>{label}</h4></Tag>)}</Space>*/}
        </div>
  </Card>
}

export default ItemActivityCard;
