import React from "react";
import PropTypes from "prop-types";
import GetDocumentFirestore from "@web/ui/containers/GetDocumentFirestore";
import MessageCard from "./MessageCard";
import FileCard from "./FileCard";
import ContactCard from "./ContactCard";
import MarkdownTimeline from "./MarkdownTimeline";
import Day from "./Day";
import DatasetData from "@web/ui/containers/DatasetData";
import DatasetCard from "./DatasetCard";
import GoogleSearch from "./GoogleSearch";
import NoteCard from "./NoteCard";
import ReminderCard from "./ReminderCard";
import BusinessCard from "./BusinessCard";
import TimelinePaymentsCard from "./TimelinePaymentsCard";
import InlineItem from "@web/ui/containers/InlineItem";
import TimelineDeliveriesCard from "./TimelineDeliveriesCard";
import GridItemCard from "./GridItemCard";

UserContentPreview.propTypes = {
  path: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  data: PropTypes.object,
  index: PropTypes.number,
  length: PropTypes.number,
  children: PropTypes.node.isRequired,
};

function UserContentPreview({path, href, children, ...props}) {
  const pathParts = path.split("/");
  const serviceId = pathParts.length > 2 ?
    pathParts[2] :
    pathParts[0];

  switch(serviceId) {
    // case "todos":
    //   return <div style={{display: "flex", width: "300px", maxWidth: "300px"}} className="card-content-wrapper">
    //     <GetDocumentFirestore dataField="todo" path={path}>
    //       <TodoCard path={path} />
    //     </GetDocumentFirestore>
    //   </div>
    // case "threads": {
    //   return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
    //     <EmailDetailData
    //       path={path} component={EmailsCard}
    //       childProps={{pageSize: 1, card: true}}
    //     />
    //   </div>
    // }
    case "threads":
    case "messages":
    case "emails":
      const messagePath = path.replace("/emails/", "/messages/").replace("/threads/", "/messages/");
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <InlineItem name="Email message">
          <GetDocumentFirestore dataField="message" path={path}>
            <MessageCard path={messagePath} />
          </GetDocumentFirestore>
        </InlineItem>
      </div>
    case "files":
      return <div style={{display: "flex", width: "100%", maxWidth: "600px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="file" path={path}>
          <FileCard size="xl" bordered={false} />
        </GetDocumentFirestore>
      </div>
    case "contacts":
      return <div style={{display: "flex", width: "300px", maxWidth: "300px"}}>
        <GetDocumentFirestore dataField="contact" path={path}>
          <ContactCard />
        </GetDocumentFirestore>
      </div>
    case "activity": {
      return <div className="fat-chip" style={{display: "flex", width: '100%', maxWidth: "600px"}}>
        <MarkdownTimeline
          markdown={props.data?.activitySummary}
          action={[
            <span className="muted uppercase" style={{fontSize: '12px', paddingTop: '3px'}}>
              <Day ts={props.data?.date} format={"MMM DD YYYY"}/>
            </span>
          ]}
        />
      </div>
    }
    case "google-search": {
      return <div style={{display: "flex", width: '100%', maxWidth: "800px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="data" path={path}>
          <GoogleSearch />
        </GetDocumentFirestore>
      </div>
    }
    case "notes":
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="data" path={path}>
          <NoteCard />
        </GetDocumentFirestore>
      </div>
    case "grid":
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="data" path={path}>
          <GridItemCard />
        </GetDocumentFirestore>
      </div>
    case "reminders":
      return <div style={{display: "flex", width: '100%', maxWidth: "600px", marginTop: '10px'}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="data" path={path}>
          <ReminderCard />
        </GetDocumentFirestore>
      </div>
    case "payments":
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <InlineItem name="Payment">
          <GetDocumentFirestore
            dataField="payment"
            path={path}
            date={props.date}
            primary={props.primary}
          >
            <TimelinePaymentsCard />
          </GetDocumentFirestore>
        </InlineItem>
      </div>
    case "deliveries":
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <InlineItem name="Delivery">
          <GetDocumentFirestore
            dataField="delivery"
            path={path}
            date={props.date}
            primary={props.primary}
          >
            <TimelineDeliveriesCard />
          </GetDocumentFirestore>
        </InlineItem>
      </div>
    case "business-profile":
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="data" path={path}>
          <BusinessCard />
        </GetDocumentFirestore>
      </div>
    case "datasets":
      return <div style={{display: "flex", width: "100%", maxWidth: "1200px"}} className="card-content-wrapper">
        <DatasetData path={path} component={DatasetCard} />
      </div>
    default:
      return <a className="underline" href={href}>{children || href}</a>
  }
}

// export default UserContentPreview;


const MemoizedComponent = React.memo(
  UserContentPreview, (prevProps, nextProps) => {
    return prevProps.path === nextProps.path;
  });

export default MemoizedComponent;
