import React, {useEffect} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import { signInAnonymously } from 'firebase/auth'
import {auth} from '@web/lib/firebase'
import {browserLocalPersistence, setPersistence} from 'firebase/auth'
import Loading from '@ai-antd/components/Loading'

export const signInAnon = () => {
  console.log('signInAnon')
  return setPersistence(auth, browserLocalPersistence)
    .then(() => signInAnonymously(auth))
    .then(user => console.log('anonymous', user))
}

const AnonymousAuth = (props) => {
  const [user, loading, error] = useAuthState(auth)
  useEffect(() => {
    if (!user && !loading && !error) {
      signInAnon();
    }
  }, [user, loading, error]);

  if (loading) return <Loading inline />
  if (!user) return null
  return props.children
}

export default AnonymousAuth
