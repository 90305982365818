import React from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from "./AudioPlayer";
import {Alert, Card} from "antd";
import StorageFile from "@web/ui/containers/StorageFile";
// import {AudioFileIcon, AudioIcon, HeadphonesIcon, WaveformIcon} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import {AlertIcon} from "@web/assets/icons";
import IconTip from "./IconTip";
// import {api} from "@web/lib/api";

AudioCard.propTypes = {
  file: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

function AudioCard(props) {
  const dispatch = useDispatch();
  const activeItemPath = useSelector((state) =>
    state.App[state.App.activeDetailServiceId]?.props?.path);

  // console.log("AudioCard:activeItemPath", activeItemPath, props.path);
  const setActiveDetail = React.useCallback((serviceId, props) => {
    dispatch(appActions.setActiveDetail(
      serviceId,
      props,
      true),
    );
  }, [dispatch]);
  // const onClickTranscribe = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   return false;
  // }

  return <Card
    hoverable={activeItemPath !== props.path}
    onClick={() =>
      setActiveDetail(
        "files",
        {file: props.file, path: props.path})
    }
    title={(!!props.file.alert && !props.preview) ?
      <IconTip
        key="warning"
        title={props.file.alert.message}
        placement="right"
      >
        <label className="bolded font-lg space space-center-y">
          Audio <AlertIcon size={20} style={{color: "orange"}} />
        </label>
      </IconTip> :
    <span className="bolded font-lg">Audio</span>}
    style={{width: '100%', height: 'inherit'}}
    className="card-simple card-transparent"
    bordered={false}
  >
    {props.preview &&
      props.file.alert &&
      <Alert
        style={{margin: '10px', borderRadius: '14px'}}
        showIcon
        icon={<AlertIcon size={24} style={{color: "orange"}} />}
        description={props.file.alert.description}
        type={props.file.alert.type}
      />}
    {props.preview &&
      !props.file.alert &&
      <StorageFile storageURI={props.file.storageURI}>
        <AudioPlayer
          transcriptPath={props.file.transcriptPath}
          mimeType={props.file.mimeType}
        />
      </StorageFile>}
  </Card>
}

export default AudioCard;
