import React from "react";
import {Alert, Button} from 'antd'
import {MicrosoftIcon} from '@web/assets/icons'
import {OAuthProvider, signInWithPopup, getAdditionalUserInfo, fetchSignInMethodsForEmail, signInWithCredential, linkWithCredential} from "firebase/auth"
import {auth} from '@web/lib/firebase'
import {AppContext, WindowContext} from "@web/hooks/context";
import useLocalStorage from '@web/hooks/useLocalStorage'
import {safeValueForDB, setDocument, setDocumentSub} from '@web/lib/firestore.db'
import {string} from '@web/lib/regex'
import now from 'lodash/now'
import {useLocation} from 'wouter'
import useDocumentFirestore from '@web/hooks/useDocumentFirestore'
import uniq from 'lodash/uniq'
import PropTypes from 'prop-types'
import RefreshSourceButton from "./RefreshSourceButton";
import {notifyAndTrack} from "./notification";
import {api} from "@web/lib/api";
import dayjs from "dayjs";
import {ProgressBar} from "./ProgressBar";

const expiredToken = "InvalidAuthenticationToken";
const provider = new OAuthProvider('microsoft.com');
export const SCOPES = {
  readonly: {
    openId: 'openid',
    // mail: 'mail.read',
    // calendar: 'calendars.read',
    // activity: 'activity.read',
    // files: 'files.read',
  },
  // readwrite: {
  //   openId: 'openid',
  //   mail: 'mail.readwrite',
  //   calendar: 'calendars.readwrite',
  //   activity: 'activity.readwrite',
  //   files: 'files.readwrite',
  //   tasks: 'tasks.readwrite',
  // },
};
const customParameters = {
  tenant: 'common',
};

const updateUser = ({user, profile, credential}, requestedScopes = []) => {
  const providerId = credential.providerId
  const providerKey = string.replacePunctuationAndSpaces(providerId, "-")
  const createdTs = +user.metadata.createdAt
  const {
    granted_scopes: grantedScopes = [],
    preferredLanguage,
    givenName: firstName,
    surname: lastName,
  } = profile.profile;

  const userPayload = {};
  userPayload.updatedTs = now();
  userPayload.lastLoginTs = +user.metadata.lastLoginAt;
  userPayload.lastLoginProvider = providerId || null;
  profile.isNewUser && (userPayload.createdTs = createdTs);
  const payload = {
    client: {
      oauthCredentials: {
        ...safeValueForDB(credential.toJSON()),
        expireTs: dayjs().add(1, 'hour').valueOf(),
      },
      grantedScopes: grantedScopes?.length ?
        grantedScopes :
        requestedScopes, // Todo: grantedScopes Will be empty array. Microsoft does not return granted scopes in oauth flow so we use requested scopes instead. This could be buggy if user disapproves some requested scopes and approves others
      email: user.email,
      firstName,
      lastName,
    },
  }
  return setDocument("user", user.uid, userPayload)
    .then(() =>
      setDocumentSub(
        ["user", user.uid, "providers"],
        providerKey,
        payload,
        {merge: true},
      ))
    .catch(err => console.error(err));
}

MicrosoftAuthButton.propTypes = {
  forceConsent: PropTypes.bool,
  scopes: PropTypes.arrayOf(PropTypes.string),
}

function MicrosoftAuthButton(props) {
  const [, user] = React.useContext(AppContext);
  const [, width, height] = React.useContext(WindowContext);
  const [
    userAuth,
    loadingUserAuth = false,
    errorLoadingUserAuth = false,
  ] = useDocumentFirestore(
    ["user", user?.uid, 'providers', 'microsoft-com'],
  );

  const [, setAnonUID] = useLocalStorage('anon-user-id')
  const [outlookEmail, setOutlookEmail] = useLocalStorage(`microsoft-com-email`)
  const [,setLocation] = useLocation()
  const [isLoggingIn,setIsLoggingIn] = React.useState(false)
  const [authorized, setAuthorized] = React.useState(false)
  const [loaderErrorCode, setLoaderErrorCode] = React.useState("")
  const {scopes: requestedScopes = []} = props
  const {grantedScopes = []} = userAuth || {};
  const expireTs = userAuth?.oauthCredentials?.expireTs || 0;
  const scopesToRequest = loaderErrorCode === expiredToken ?
    requestedScopes :
    requestedScopes.filter(scope =>
      !grantedScopes?.includes(scope));

  const setUnauthorized = React.useCallback(() =>
    setAuthorized(false),
    [authorized]);

  const onLoaderError = React.useCallback(({code, message}) => {
    console.log("onLoaderError", {code, message});
    if (code === expiredToken) {
      setUnauthorized();
      setLoaderErrorCode(code);
      notifyAndTrack.info('Conformation required',`For your security, click connect to refresh your ${props.integration?.name} connection`, {duration: 8});
    } else {
      notifyAndTrack.error(code,`I was unable to update ${props.integration?.name}`, {duration: 8});
    }
  }, [authorized, loaderErrorCode])

  React.useEffect(() => {
    if (loadingUserAuth) return;
    if (!user) return;
    if (user.isAnonymous) return;
    if (scopesToRequest?.length) return;
    if (!props.integration?.loader) return;
    if (isLoggingIn) return;
    if (loaderErrorCode === expiredToken) return;
    (expireTs > now()) &&
    setAuthorized(true);

    !!userAuth?.oauthCredentials?.refreshToken &&
    setAuthorized(true);
  }, [
    user,
    userAuth,
    expireTs,
    scopesToRequest,
    isLoggingIn,
    loadingUserAuth,
    loaderErrorCode,
    props.integration?.loader,
  ]);

  if (authorized && props.integrationId) {
    return <RefreshSourceButton
      loader={props.integration?.loader}
      onLoadError={onLoaderError}
      onStart={props.onStart}
    />
  }

  const scopes = uniq([...scopesToRequest]);

  scopes.forEach((scope) => provider.addScope(scope));

  const lastLoginEmail = userAuth?.email || user?.email || outlookEmail;

  provider.setCustomParameters({
    ...customParameters,
    login_hint: lastLoginEmail,
  });

  if (props.forceConsent) {
    provider.setCustomParameters({
      ...customParameters,
      login_hint: lastLoginEmail,
      prompt: 'consent',
    });
  }

  if (loadingUserAuth) {
    return null;
  }

  if (errorLoadingUserAuth) {
    return <Alert type="info" message="An error occurred" />
  }

  const onClickSignIn = () => {
    if (user?.uid && props.integrationId) {
      setIsLoggingIn(true);
      return api.call(
        'microsoft-authLink',
        {integrationId: props.integrationId})
      .then(({data, error}) => {
        if (error) {
          return notifyAndTrack.error(
            error,
            'Unable to complete your request');
        }
        console.log('microsoft-authLink:', data);
        window.open(
          data.authUrl,
          "_blank",
          `popup=true,width=800,height=800,noreferrer,left=${(width / 2) - 400},top=${(height / 2) - 400}`,
        );
        setIsLoggingIn(false);
      })
      .catch((err) => {
        console.log("microsoft-authLink error", err.message);
        notifyAndTrack.error('Unable to complete your request');
      });
    }

    user?.isAnonymous && setAnonUID(user.uid);

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const user = result.user;
        const profile = getAdditionalUserInfo(result);
        console.log('signInWithPopup profile', profile);
        setIsLoggingIn(false);
        setLoaderErrorCode("")
        return {user, profile, credential}
      })
      .then((payload) =>
        updateUser(payload, scopes))
      .then(() =>
        setLocation('/'))
      .catch((error) => {
        setIsLoggingIn(false);
        setLoaderErrorCode(0);
        if (error.code === 'auth/account-exists-with-different-credential') {
          const credential =
            OAuthProvider.credentialFromError(error);// as OAuthCredential;

          linkWithCredential(user, credential)
            .then((userCredential) => {
              const user = userCredential.user;
              const credential = userCredential.credential;
              const profile = getAdditionalUserInfo(userCredential);
              return {user, profile, credential}
            })
            .then((payload) =>
              console.log({payload, scopes}));

          // signInWithCredential(auth, credential)
          //   .then((userCredential) => {
          //     // Signed in
          //     const user = userCredential.user;
          //     const credential = userCredential.credential;
          //     console.log('credential', credential, user);
          //     // ...
          //   });
          // fetchSignInMethodsForEmail(auth, error.email)
          //   .then((providers) => {
          //     console.log('providers', providers);
          //     const provider = providers[0];
          //     // return signInWithCredential(auth, credential);
          //   });
          // const profile = getAdditionalUserInfo(error);
          // const user = error.user;
        }
      });
  }
  return <Button
    disabled={isLoggingIn}
    block={!!props.block}
    size={props.size || "large"}
    onClick={onClickSignIn}
    className="icon-btn"
  >
    <MicrosoftIcon
      size={props.size === "default" ? 14 : 16}
      style={{marginRight: '10px', verticalAlign: 'middle'}}
    />
    {isLoggingIn ?
      <ProgressBar visible/> :
      (props.children || "Login with Microsoft")}
  </Button>
}

export default MicrosoftAuthButton
