import React from "react";
import {Button, Carousel} from "antd";
import backgroundActions from "@web/redux/background/actions";
import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch} from "react-redux";
import {UserTask} from "./GuideCards";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import appActions from "@web/redux/app/actions";

GuidesRouter.propTypes = {
  user: PropTypes.object.isRequired,
  list: PropTypes.array,
  initialStepIndex: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onCompleteStep: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default function GuidesRouter(props) {
  const dispatch = useDispatch();
  const slider = React.useRef({});
  const [assistantData,,, snapshot] = useDocumentFirestore(["user", props.user.uid, "lines", "assistant"].join("/"));
  const [disableNavigation, setDisableNavigation] = React.useState(false);

  const startAnimation = React.useCallback(() =>
    dispatch(backgroundActions.startAnimation()), [dispatch]);

  const pauseAnimation = React.useCallback(() =>
    dispatch(backgroundActions.pauseAnimation()), [dispatch]);

  const navigateNext = React.useCallback(() => {
    slider.current?.next();
  }, []);

  const markCompleteAndNavigateNext = React.useCallback((path) => {
    props.onCompleteStep && props.onCompleteStep(path);
    navigateNext();
  }, []);

  const onExit = React.useCallback(() => {
    props.onExit && props.onExit();
  }, []);

  const onBeforeChange = React.useCallback((currentIndex, nextIndex) => {
    currentIndex < nextIndex && markCompleteAndNavigateNext(props.list[currentIndex][0]);
  }, [props.list]);

  const setContext = React.useCallback((context) => {
    dispatch(appActions.setActiveDetail("lines", context, false));
  }, [dispatch]);

  if (!props.user) return null;

  if (!props.list) return null;

  if (!assistantData) return null;

  return <section style={{position: 'relative'}}>
    <Carousel
      ref={(ref) => slider.current = ref}
      initialSlide={props.initialStepIndex}
      className="splash-carousel splash-carousel-dots"
      adaptiveHeight
      prevArrow={null}
      nextArrow={
      !props.isMobile &&
        !disableNavigation &&
        <Button icon={<RightArrowOutlined />} />
      }
      arrows={false}
      swipe={!disableNavigation}
      dots={!disableNavigation}
      accessibility={!props.isMobile && !disableNavigation}
      beforeChange={onBeforeChange}
      // afterChange={(index) => setCurrentIndex(index)}
      onSwipe={() => null}
      lazyLoad="ondemand"
      infinite={false}
      draggable={false}
      waitForAnimate={true}
    >
      {props.list?.map?.(([path, data, component], index) => {
        return React.createElement(component || UserTask, {
          key: path,
          assistantPath: snapshot.ref.path,
          assistant: assistantData,
          path: path,
          user: props.user,
          isMobile: props.isMobile,
          width: props.width,
          height: props.height,
          data,
          setContext,
          setDisableNavigation,
          pauseAnimation,
          startAnimation,
          navigateNext,
          onExit: onExit,
          markCompleteAndNavigateNext,
        });
      })}
    </Carousel>
  </section>
}
