import React, {useContext} from "react";
import {Empty, List} from "antd";
import {WindowContext} from "@web/hooks/context";
import ContactsListItem from "./ContactsListItem";
import Loading from "./Loading";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import GroupTitle from "./GroupTitle";
import PropTypes from "prop-types";

ContactsList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
}

function ContactsList(props) {
  const [,, height] = useContext(WindowContext);

  return <div
    id="services-contacts-scroll-target"
    style={{height: height - 60, overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
    <Pager
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-contacts-scroll-target"
    >
      <List
        header={
          <GroupTitle
            title="Activity"
            style={{paddingLeft: '15px'}}
          />}
        className="services-contacts-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <ContactsListItem
            key={path}
            path={path}
            date={date}
          />}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default ContactsList;
