import React, {useContext} from "react";
import {Alert, Card, Empty, List} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
import MarkdownListItem from "./MarkdownListItem";
import Loading from "./Loading";
import PropTypes from "prop-types";
import LineConnector from "./LineConnector";
import Pager from "@web/ui/containers/Pager";

DeliveriesList.propTypes = {
  list: PropTypes.array,
  error: PropTypes.object,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

function DeliveriesList(props) {
  const [, user] = useContext(AppContext);
  const [,, height] = useContext(WindowContext);

  if (!user?.uid) return null;

  if (props.error) {
    return <Alert message={props.error.message} type="error" showIcon closable />;
  }

  return <div
    id="services-deliveries-scroll-target"
    style={{height: height - 60, overflow: 'auto', display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 0}}>
    <Pager
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-deliveries-scroll-target"
    >
      <List
        className="list-empty-no-padding list-item-no-divider"
        header={<h6>Deliveries</h6>}
        dataSource={props.list}
        renderItem={([path, date]) =>
          <MarkdownListItem
            key={path}
            path={path}
            date={date}
            dataKey="deliveries"
          />
        }
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
            <Card className="card-simple card-rounded card-no-header" bordered={false}>
              No deliveries to show
            </Card>
          </Empty>
        }}
      />
    </Pager>
  </div>;
}

export default DeliveriesList;
