import actions from "./actions";
import uniq from "lodash/uniq.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  itemsInView: [],
};

/**
 * @summary Reducer for ai stream
 * @param {object} state
 * @param {object} data
 * @param {string} data.type
 * @param {{chatId:string,chat:models.Chat.Data,path:string}} data.stream
 * @return {*}
 */
export default function reducer(
  state =
    initState, {
    type,
    agentId,
    stream,
    streamDone,
    draftPath,
    path,
    payload,
  }) {
  switch (type) {
    case actions.AI_WRITE_STREAM:
      return {
        ...state,
        [agentId]: [{
          ...stream.chat,
          answers: [
            ...stream
            .chat
            .answers
            .map((answer) =>
              ({...answer})),
            ].filter(Boolean),
          },
          stream.path,
          streamDone,
        ],
      };
    case actions.AI_CLEAR_STREAM:
      return {};
    case actions.SET_ACTIVE_EMAIL_DRAFT:
      return {
        ...state,
        draft: {
          draftPath,
          collapse: true,
        },
      };
    case actions.CLEAR_ACTIVE_EMAIL_DRAFT: {
      return {
        ...state,
        draft: null,
      };
    }
    case actions.SET_ACTIVE_USER_TASK: {
      return {
        ...state,
        userTask: {
          path,
        },
      };
    }
    case actions.CLEAR_ACTIVE_USER_TASK: {
      return {
        ...state,
        userTask: null,
      };
    }
    case actions.LOG_ITEM_VISIBILITY: {
      return {
        ...state,
        itemsInView: payload.inView ?
          uniq(payload.fromBottom ?
            [...state.itemsInView, payload.path] :
            [payload.path, ...state.itemsInView],
          ) :
          state.itemsInView.filter((itemPath) => itemPath !== payload.path),
      };
    }
    default:
      return state;
  }
}
