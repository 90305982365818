import React, {useCallback} from "react";
import {List} from "antd";
// import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
// import ExternalLink from "./ExternalLink";
import useDomainIcon from "@web/hooks/useDomainIcon";
import ListItemActive from "./ListItemActive";

function ContactsListItem(props) {
  const dispatch = useDispatch();
  const isMobile = true;
  const contact = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });
  const DomainIcon = useDomainIcon(contact?.domain, contact?.indicators);
  const onClick = useCallback((e) => {
    const data = {
      path: props.path, contact,
    };

    if (props.onClick) {
      return props.onClick(data, e);
    }
    dispatch(appActions.setActiveDetail("contacts", data, true));
  }, [dispatch,props]);

  if (!contact) {
    return null
  }

  const displayName = contact.name?.substring(0, 20)
    .replaceAll('"', '') || contact.email;

  return <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[]}
    onClick={onClick}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={DomainIcon}
          userId={props.path.split("/").pop()}
          name={contact.name}
          displayName={displayName}
          src={contact.photoURL}
        />}
      title={
        <div className="space space-center-y">
          <h6 className="capitalize">
            {displayName}
          </h6>
          <span key="ts" className="muted font-sm">
            <Day ts={contact.activityTs}/>
          </span>
        </div>}
      description={
      <span className="lowercase muted">
        {contact?.domain}
      </span>}
  />
  <ListItemActive
    path={props.path}
    offset={'5px'}
  />
  </List.Item>
}

export default ContactsListItem;
