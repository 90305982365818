import React from "react";
import TodosList from "./TodosList";
import ServiceData from "@web/ui/containers/ServiceData";
import {Card} from "antd";

TodosExplorer.propTypes = {

};

// todo - reimplement load todos w/o serviceData
function TodosExplorer() {
  return <Card className="card-simple card-transparent card-rounded extra-round w-full">
    {/*<ServiceData*/}
    {/*  serviceId="todos"*/}
    {/*  component={TodosList}*/}
    {/*  // delay={1000}*/}
    {/*/>*/}
  </Card>
}

export default TodosExplorer;
