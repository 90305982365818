import {call, cancelled, put, take} from "redux-saga/effects";
import {eventChannel} from "redux-saga";
import {DocumentSnapshot, onSnapshot, QuerySnapshot} from "firebase/firestore";

/**
 * @typedef {Object} FirestoreQueryItems
 * @property {Object} data
 * @property {string[]} list
 * @property {FirestoreDocumentItem[]} items
 */

/**
 * @typedef {Object} FirestoreDocumentItem
 * @property {Object} [data]
 * @property {string} id
 * @property {string} key
 * @property {string} path
 */

/**
 * Transform a query snapshot into a data object and a list of ids
 * @param {QuerySnapshot | {docs: DocumentSnapshot[]} | Error} querySnapshot
 * @param {string} [sortKey]
 * @return {FirestoreQueryItems}
 */
export function toFirestoreQueryItems(querySnapshot, sortKey) {
  const payload = {data: {}, list: [], items: []};
  if (querySnapshot instanceof Error) {
    return payload;
  }
  if (!querySnapshot) {
    return payload;
  }
  return querySnapshot.docs.reduce(($acc, document) => {
    const item = {id: document.id, key: document.id, path: document.ref.path};
    if (sortKey) item[sortKey] = document.get(sortKey);
    $acc.data[document.id] = document.data();
    $acc.list.push(document.id);
    $acc.items.push(item);
    return $acc;
  }, payload);
}

/**
 * Transform a query snapshot into a data object and a list of ids
 * @param {DocumentSnapshot[]} docSnapshots
 * @returns {[]}
 */
export function toPairs(docSnapshots = []) {
  return docSnapshots.reduce(($acc, docSnapshot) => {
    if (!docSnapshot.exists) return $acc;
    $acc.push([docSnapshot.id, docSnapshot.data()]);
    return $acc;
  }, []);
}

/**
 * Transform a document snapshot into a data object and an id
 * @param {DocumentSnapshot} documentSnapshot
 * @return {FirestoreDocumentItem}
 */
export function toFirestoreDocumentItem(documentSnapshot) {
  return {
    data: documentSnapshot.data(),
    id: documentSnapshot.id,
    path: documentSnapshot.ref.path,
    key: documentSnapshot.id
  };
}

const getPayload = snapshot => {
  return snapshot.id ?
    toFirestoreDocumentItem(snapshot) :
    toFirestoreQueryItems(snapshot);
};

let activeActions = [];
const listenTo = (actions = []) => {
  // First, prevent duplicate listeners
  const newActions = actions.filter(({success, key}) => activeActions.indexOf(key || success) < 0);
  activeActions = activeActions.concat(newActions.map(({success, key}) => key || success));
  return eventChannel((emit) => {
    const unsubscribes = newActions.map(({ref, success, error, ...rest}) => {
      return onSnapshot(ref, (snapshot) => {
        emit({
          payload: getPayload(snapshot),
          type: success,
          ...rest,
        });
      });
    });
    return () => {
      console.log("unsubscribing from firestore");
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  });
};

export function* firestore(actions = []) {
  const channel = yield call(listenTo, actions);
  try {
    while (true) {
      const result = yield take(channel);
      yield put(result);
    }
  } finally {
    if (yield cancelled()) channel.close();
  }
}
