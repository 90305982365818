import React from 'react'
import {Divider, Space, Typography} from "antd";
import { useAuthState } from 'react-firebase-hooks/auth'
import {auth} from '@web/lib/firebase'
import {Link, Redirect} from 'wouter'
import EmailAuthPage from './EmailAuthPage'
import HotClick from '@web/ui/containers/HotClick'
import LoginLinks from '@ai-antd/components/LoginLinks'
import GoogleAuthButton from "@ai-antd/components/GoogleAuthButton";
import MicrosoftAuthButton from "@ai-antd/components/MicrosoftAuthButton";
import AuthLayoutWrapper from "@ai-antd/components/AuthLayoutWrapper";
import {WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";

SignUp.propTypes = {
  title: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default function SignUp({title = "A new way to work.", providers = []}) {
  const [user] = useAuthState(auth);
  if (user && !user.isAnonymous) {
    return <Redirect to="/" />;
  }

  return <AuthLayoutWrapper hideLinks>
    <Typography.Title level={2} style={{margin: '20px 40px 0'}}>{title}</Typography.Title>
      <EmailAuthPage intent="sign-in" />
      <Divider />
      <div className="space space-col gap-30">
        {providers.indexOf("google.com") >= 0 ? <GoogleAuthButton block>Login with Google</GoogleAuthButton> : null}
        {providers.length > 1 && <Divider className="small-top small-bottom"/>}
        {providers.indexOf("microsoft.com") >= 0 ? <MicrosoftAuthButton block/> : null}
        <HotClick>
          <Link to="/login" style={{color: 'green', marginTop: '10px', display: 'inline-block', textDecoration: 'underline'}}>I already have an account</Link>
        </HotClick>
      </div>
  </AuthLayoutWrapper>
}
