import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {getSelectedUserId} from "@web/redux/activity/selectors";
import appActions from "@web/redux/app/actions";

ItemActivityData.propTypes = {
  children: PropTypes.node.isRequired,
  itemPath: PropTypes.string,
};

function  ItemActivityData({itemPath, ...props}) {
  const dispatch = useDispatch();
  const selectedUserId = useSelector(getSelectedUserId);
  const event = useSelector((state) => {
   return selectedUserId ?
     state.Activity.activity?.events?.[state.Activity.activity?.eventsByMember?.[selectedUserId]?.[itemPath]?.[0]] :
     state.Activity.activity?.events?.[state.Activity?.activity?.eventsByItem?.[itemPath]?.[0]];
  });

  const onClick =
    useCallback(() => event &&
    dispatch(
      appActions.setActiveDetail(
        itemPath.split("/")[2],
        {path: event.activityPath},
        true),
      ),
      [selectedUserId],
    );

  const title = event?.update?.recommendedTodo?.summary ||
    event?.recommendedTodo?.summary ||
    event?.update?.summary ||
    event?.summary;

  const hasTodo = (event?.recommendedTodo || event?.update?.recommendedTodo) &&
    event?.update?.recommendedTodo?.todoStatus !== "void";
  const dueDate = hasTodo ? event?.update?.recommendedTodo?.dueDate || event?.recommendedTodo?.dueDate : "";
  const subtitle = hasTodo ? event?.update?.summary || event?.summary : "";
  const status = hasTodo ? event?.update?.recommendedTodo?.todoStatus || event?.recommendedTodo?.todoStatus : "";

  // if (!event) return null;
  console.log("ItemActivityData", {itemPath, title, hasTodo, dueDate, subtitle, status});
  return React
  .cloneElement(React.Children.only(props.children), {
    activityPath: event?.activityPath, title, hasTodo, dueDate, subtitle, itemPath, onClick, status,
    //onView,
  })
}

export default ItemActivityData;
