import {createContext} from 'react'

export const WindowContext = createContext([])
/**
 * @typedef {Array} AppContextData
 * @property {Object} 0 - The app
 * @property {User} 1 - The AuthUser
 * @property {Object} 2 - The AuthClaims
 * @property {Object} 3 - The Nav Tree
 */
export const AppContext = createContext([])
export const ProductContext = createContext({})
export const ChatContext = createContext({})
export const ScribeContext = createContext({})
export const WorkspaceContext = createContext([])
export const SourcesContext = createContext({})
export const DetailContext = createContext(null)
export const LinesContext = createContext(null)
