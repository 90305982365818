import React from "react";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";

GetDocumentFirestore.propTypes = {
  dataField: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function GetDocumentFirestore({dataField, path, children, ...props}) {
  const [document, loading, error] = useDocumentFirestore(path);
  if (loading) {
    return null;
  }
  // console.log("GetDocumentFirestore", path, document);
  return React.cloneElement(React.Children.only(children), {[dataField]: document, path, loading, error, ...props})
}

const MemoizedComponent = React.memo(
  GetDocumentFirestore, (prevProps, nextProps) => {
    return prevProps.path === nextProps.path;
  });

export default MemoizedComponent;
