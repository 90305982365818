import React, {useCallback} from "react";
import {List} from "antd";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
import useDomainIcon from "@web/hooks/useDomainIcon";
import ListItemActive from "./ListItemActive";
import {reverseKey} from "@web/lib/firestore.db";

function LinesListItem(props) {
  const dispatch = useDispatch();
  const isMobile = true;
  const line = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const DomainIcon = useDomainIcon(line?.domain, line?.indicators);
  const onClick = useCallback((e) => {
    const data = {
      path: props.path, line,
    };

    if (props.onClick) {
      return props.onClick(data, e);
    }
    dispatch(appActions.setActiveDetail("lines", data, true));
  }, [dispatch,props]);

  if (!line) {
    return null
  }

  const displayName = line.assistantName || line.number || reverseKey(props.path.split("/").pop()).replace("_", "+");

  return <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[]}
    onClick={onClick}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={DomainIcon}
          userId={props.path.split("/").pop()}
          name={line.number}
          displayName={displayName}
          src={line.photoURL}
        />}
      title={
        <div className="space space-center-y">
          <h6 className="capitalize">
            {displayName}
          </h6>
          <span key="ts" className="muted font-sm">
            <Day ts={line.activityTs}/>
          </span>
        </div>}
      description={
        <span className="lowercase muted">
        {"subscriber"}
      </span>}
    />
    <ListItemActive
      path={props.path}
      offset={'5px'}
    />
  </List.Item>
}

export default LinesListItem;
