import React from "react";
import PropTypes from "prop-types";
import {Card, Statistic} from "antd";
import {DollarCircleOutlined} from "@ant-design/icons";

PaymentMiniCard.propTypes = {
  buttonId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

function PaymentMiniCard({buttonId, amount, text, ...props}) {
  console.log('PaymentMiniCard', {buttonId, amount, ...props});
  return <Card className="card-rounded card-simple" style={{backgroundColor: 'rgba(0,0,0,.6)', borderColor: 'transparent', borderTopLeftRadius: 0, maxWidth: '360px'}}>
    <div className="space space-col">
      <Statistic
        title={text}
        value={amount}
        prefix={<DollarCircleOutlined />}
      />
      <stripe-buy-button
        buy-button-id={buttonId}
        publishable-key="pk_live_51N0VTkJEZgSQPP4ETiVRbYbYct5pXDuDpzGPThqCsVDU7ibKU5xqa3VJySfzGdvfTRDHfyeUVkU9oLOXHFY124Sw00iPR0fcOv">
      </stripe-buy-button>
    </div>
  </Card>
}

export default PaymentMiniCard;
