import React from "react";
import PropTypes from "prop-types";
import {Button, Card, Tag} from "antd";
import StatusActions from "./StatusActions";
import MarkdownTimeline from "./MarkdownTimeline";
import {RightArrowOutlined} from "@web/assets/icons";

GridItemCard.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string,
  collapsed: PropTypes.bool,
};

function GridItemCard(props) {
  const [collapsed, setCollapsed] =
    React.useState(props.collapsed);

  if (!props.data) {
    return null;
  }

  if (collapsed) {
    return <Button
      size="large"
      type="link"
      className="icon-btn icon-left underline"
      onClick={() => setCollapsed(false)}>
      <RightArrowOutlined /> Note
    </Button>
  }

  return <Card
    title={<h6>Item</h6>}
    className="card-rounded card-simple ant-card-body"
    hoverable
  >
    <div style={{position: 'relative'}} className="space space-col space-between">
      <MarkdownTimeline markdown={props.data.details}/>
      <h6 style={{position: "absolute", bottom: "-34px", left: "0", right: "0", paddingTop: "6px"}}>
        <div className="space space-center-y space-between">
          <div>
            {props.data.labels?.map((label) => <Tag key={label}>{label}</Tag>)}
          </div>
          <StatusActions
            path={props.path}
            iconClassName="ai-text"
            actions={['delete']}
          />
        </div>
      </h6>
    </div>
  </Card>
}

export default GridItemCard;
