import React from 'react'
import {Link} from 'wouter'
import {ga} from '@web/lib/firebase'
import PropTypes from 'prop-types'
import HotClick from '@web/ui/containers/HotClick'

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  showCode: PropTypes.string,
  href: PropTypes.string,
}

function Logo({name, showCode, href, className = ''}) {
  const content = <h1 className={`logo-text ${className}`} title="GetLine AI">
    {name}
    {showCode && <code className="logo-beta muted">{showCode}</code>}
  </h1>
  return <div className="logo-wrapper space space-center-y" style={{position: 'relative'}}>
    <HotClick>
      {href ?
        <Link to={href} className="logo-wrapper cursor-pointer" onClick={ga.click}>
          {content}
        </Link> :
        <div className="logo-wrapper" onClick={ga.click}>{content}</div>
      }
    </HotClick>
  </div>
}

export default Logo
