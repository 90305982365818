import {getServices} from "@oven/app-thescribe/src/dashboard.config";
import contentActions from "../content/actions";

const actions = {
  SHOW_OPTIONS_DRAWER: "SHOW_OPTIONS_DRAWER",
  HIDE_OPTIONS_DRAWER: "HIDE_OPTIONS_DRAWER",
  TOGGLE_MODE_CLEAN: "TOGGLE_MODE_CLEAN",
  ENTER_CHAT_MODE: "ENTER_CHAT_MODE",
  EXIT_CHAT_MODE: "EXIT_CHAT_MODE",
  SET_ACTIVE_SOLUTION_ID: "SET_ACTIVE_SOLUTION_ID",
  SET_ACTIVE_DETAIL: "SET_ACTIVE_DETAIL",
  CLEAR_ACTIVE_DETAIL: "CLEAR_ACTIVE_DETAIL",
  SET_SHOW_DETAIL: "SET_SHOW_DETAIL",
  LAUNCH_WORKER: "LAUNCH_WORKER",
  OPEN_VIDEO_MODAL: "OPEN_VIDEO_MODAL",
  CLOSE_VIDEO_MODAL: "CLOSE_VIDEO_MODAL",
  TOGGLE_CHAT_MODE: "TOGGLE_CHAT_MODE",
  SET_ACTIVE_SERVICE_ID: "SET_ACTIVE_SERVICE_ID",
  SET_SPACEBAR_HOLD: "SET_SPACEBAR_HOLD",
  HIDE_NOTIFICATIONS: "HIDE_NOTIFICATIONS",
  SHOW_NOTIFICATIONS: "SHOW_NOTIFICATIONS",
  TOGGLE_LINES_DRAWER: "TOGGLE_LINES_DRAWER",
  TOGGLE_SHOW_SERVICES: "TOGGLE_SHOW_SERVICES",

  showOptionsDrawer: (featureId) => {
    return dispatch => {
      dispatch({
        type: actions.SHOW_OPTIONS_DRAWER,
        featureId,
      });
    };
  },
  hideOptionsDrawer: () => {
    return dispatch => {
      dispatch({
        type: actions.HIDE_OPTIONS_DRAWER,
      });
    };
  },
  setActiveServiceId: (serviceId) => {
    return (dispatch) => {
      console.log('setActiveServiceId', serviceId);
      const solutionId = getServices()[serviceId].solutionId;
      dispatch({
        type: actions.SET_ACTIVE_SERVICE_ID,
        serviceId,
        solutionId,
      });
    };
  },
  setActiveSolutionId: (solutionId, show) => {
    return (dispatch) => {
      if (!solutionId) {
        return;
      }
      dispatch({
        type: actions.SET_ACTIVE_SOLUTION_ID,
        solutionId,
        showServices: show,
      });
    };
  },
  toggleShowServices: () => {
    return (dispatch) => {
      dispatch({
        type: actions.TOGGLE_SHOW_SERVICES,
      });
    };
  },
  /**
   * @function setShowServices
   * @param {boolean} show
   * @param {dashboard.ServiceId} serviceId
   * @returns {(function(*): void)|*}
   */
  setShowServices: (show, serviceId) => {
    const solutionId = getServices()[serviceId]?.solutionId;
    console.log('>>>>>>>>appActions:setShowServices', serviceId, solutionId);
    if (!solutionId) {
      throw new Error(`No solutionId for serviceId: ${serviceId}`);
    }
    return (dispatch) => {
      dispatch({
        type: actions.SET_ACTIVE_SERVICE_ID,
        serviceId,
        solutionId,
        showServices: show,
      });
    };
  },
  hideSolutions: () => {
    return (dispatch, getState) => {
      !!getState().Content.showFilter ?
        dispatch(contentActions.setShowFilter(false)) :
        dispatch({
          type: actions.SET_ACTIVE_SERVICE_ID,
          showServices: false,
        });
    };
  },
  toggleLinesDrawer: () => {
    return (dispatch) => {
      dispatch({
        type: actions.TOGGLE_LINES_DRAWER,
      });
    };
  },
  /**
   * @function setActiveDetail
   * @param {string} serviceId
   * @param {object} props
   * @param {boolean} show
   * @returns {(function(*): void)|*}
   */
  setActiveDetail: (serviceId, props, show = false) => {
    return (dispatch) => {
      const solutionId =
        getServices()[serviceId].solutionId;
      console.log('appActions:setActiveDetail', serviceId, props, solutionId, show);
      dispatch({
        type: actions.SET_ACTIVE_DETAIL,
        solutionId,
        serviceId: serviceId === "messages" ? "threads" : serviceId,
        props,
        show,
      });
    };
  },
  clearActiveDetail: () => {
    return (dispatch) => {
      dispatch({
        type: actions.CLEAR_ACTIVE_DETAIL,
      });
    };
  },
  showDetail: () => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SHOW_DETAIL,
        showDetail: true,
      });
    };
  },
  hideDetail: () => {
    return (dispatch) => {
      dispatch({
        type: actions.SET_SHOW_DETAIL,
        showDetail: false,
      });
    };
  },
  exitChatMode: () => {
    return {
      type: actions.EXIT_CHAT_MODE,
    };
  },
  openVideoModal: ({url}) => {
    return (dispatch) => {
      console.log('openVideoModal', url);
      dispatch({
        type: actions.OPEN_VIDEO_MODAL,
        payload: {
          url,
        },
      });
    };
  },
  closeVideoModal: () => {
    return dispatch => {
      dispatch({
        type: actions.CLOSE_VIDEO_MODAL,
      });
    };
  },
  setSpacebarHold: (hold) => {
    return (dispatch) => {
      // console.log('actions:setSpacebarHolding:', hold);
      dispatch({
        type: actions.SET_SPACEBAR_HOLD,
        hold,
      });
    };
  },
  hideNotifications: () => {
    return (dispatch) => {
      dispatch({
        type: actions.HIDE_NOTIFICATIONS,
      });
    };
  },
  showNotifications: () => {
    return (dispatch) => {
      dispatch({
        type: actions.SHOW_NOTIFICATIONS,
      });
    };
  },
};

export default actions;
