import React from 'react';
import {Button, Divider, List, Tag, Typography} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
import {UpRightArrowOutlined} from "@web/assets/icons";
import {AuthLinkEmailCard} from "@ai-antd/components/AuthLinkEmailCard";
import TodosExplorer from "@ai-antd/components/TodosExplorer";

function Progress() {
  const [, user] = React.useContext(AppContext);
  const [,, height] = React.useContext(WindowContext);

  return <section
    className="space space-col middle"
    style={{position: "relative", maxWidth: '1200px', height: height - 100}}
  >
    <AuthLinkEmailCard />
    {user.emailVerified ?
      <React.Fragment>
        <div className="space space-center-y">
          <Typography.Title level={4} className="ai-text">
            What would you like to do next?
          </Typography.Title>
        </div>
        <Divider />
        <TodosExplorer />
        <List
          style={{width: "100%", margin: "0 auto"}}
          className="list-item-no-divider"
          loading={false}
          dataSource={[
            {tag: "quickies", rank: 1, color: "green"},
            {tag: "follow-ups", rank: 2, color: "orange"},
            {tag: "reminders", rank: 3, color: "yellow"},
          ]}
          renderItem={({tag, rank, color}) =>
            <List.Item key={tag}>
              <div className="fat-chip space space-center-y space-between">
                <Tag color={color} style={{borderRadius: 20, marginLeft: "-2px", borderColor: "transparent"}} draggable={true}>{tag}</Tag>
                <Button ghost>
                  <UpRightArrowOutlined className="green-icon" />
                </Button>
              </div>
            </List.Item>}
          locale={{emptyText: "No relevant activity"}}
        />
      </React.Fragment>:
      <TodosExplorer />
    }
  </section>
}

export default Progress;
