/** @namespace user.onboarding */
import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {Button, Card, Divider, Input, List, Progress, Tabs, Tag, Typography} from "antd";
import {Link, useLocation} from "wouter";
import {AppContext, WindowContext} from "@web/hooks/context";
import DashboardRow from "../DashboardRow";
import {notifyAndTrack} from "../notification";
import Icon from "../Icon";
import {
  CheckIcon,
  DownArrowOutlined,
  FeatherIcon,
  PlusCircle,
  PlusIcon,
  RightArrowOutlined,
  XIcon,
} from "@web/assets/icons";
import MemoryOriginDate from "../MemoryOriginDate";
import AppIntegrationsData from "@web/ui/containers/AppIntegrationsData";
import AppIntegrationsList from "../AppIntegrationsList";
import {updatePath, Fields} from "@web/lib/firestore.db";
import {useDispatch, useSelector} from "react-redux";
import DashboardFullRow from "../DashboardFullRow";
import UserTaskProgressManager from "../UserTaskProgressManager";
import Toggle from "../Toggle";
import {useAuthOven} from "@web/hooks/useAuthOven";
import {auth} from "@web/lib/firebase";
// import useDataRTDB from "@web/hooks/useDataRTDB";
// import SplashCard from "../SplashCard";
import PaymentMiniCard from "../PaymentMiniCard";
import DashboardCenterRow from "../DashboardCenterRow";
import InputSaveButton from "../InputSaveButton";
// import UploadButton from "../UploadButton";
// import DictateButton from "../DictateButton";
// import ChatInputTextarea from "../ChatInputTextarea";
// import ChatInputManager from "@web/ui/containers/ChatInputManager";
// import voiceActions from "@web/redux/voice/actions";

const aboutLineAiMarketplace = [
  "A New Real-Time Marketplace",
  "The Line AI Marketplace is a new kind of marketplace that connects consumers with thousands of micro-entrepreneurs. It is a real-time, AI-driven platform that dynamically matches consumer preferences with small businesses. The platform automates matchmaking, discovery, and negotiation between consumers and micro-brands. It also handles logistics, payments, and compliance.",
  "a. A Real-Time, AI-Driven Platform",
  "1. Matchmaking & Discovery: Imagine a marketplace where AI not only handles logistics and payments but also dynamically matches consumer preferences with small businesses. Think of it as a “personal shopper AI” that scours thousands of mini-brands in real time to find the perfect fit for each consumer.",
  "2. Micro-Payments & Subscription Models: With frictionless digital payments (possibly micro-payments or subscription-based), consumers can seamlessly sample products or services from many small players.",
  "3. Automated Negotiation & Pricing: AI systems could dynamically negotiate prices, shipping terms, or even content licensing between micro-brands and consumers. This might replace or augment the usual static pricing in e-commerce sites.",
  "b. Characteristics of This Marketplace",
  "1. Line AI Agents on Both Sides: Consumers have personal AI that hunts for deals or curates brand recommendations. Sellers have AI that adjusts inventory, pricing, or promotional offers in real time.",
  "2. Fragmented Yet Interconnected: Thousands (or millions) of micro-entrepreneurs are networked in a platform that automates the “back office” (fulfillment, compliance, returns).",
  "3. Personalized Branding: Tools to let each brand project its unique persona at scale: custom chatbots with the founder’s voice, AR/VR experiences, or personalized video messages triggered by AI upon purchase.",
];

const OnboardingFooter = (props) => {
  return props.hide ?
    <div style={{height: 100}}>
      <div className="space space-center-y space-between">
        <div/>
        {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
      </div>
    </div> :
    <div style={{height: 100}}>
      <Divider/>
      <div className="space space-center-y space-between">
        <Button size="large" shape="round" onClick={() => props.onNext()}>
          <h4 className="space space-center-y green-color">{props.nextText || "Next"} <RightArrowOutlined/></h4>
        </Button>
        <div>
          {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
          {props.onExit && <Button size="large" ghost onClick={props.onExit}><span className="muted">Exit</span></Button>}
        </div>
      </div>
    </div>
}

function Wrapper(props) {
  return <div
    className={`splash-card-wrapper flex-col middle${props.isMobile ? " splash-card-wrapper-mobile" : " center"}`}
    style={{minHeight: props.isMobile ? "101lvh" : props.height + "px", padding: "20px 10px"}}
  >
    {props.children}
  </div>
}

PropTypes.Welcome = {
  setDisableNavigation: PropTypes.func.isRequired,
  pauseAnimation: PropTypes.func.isRequired,
  startAnimation: PropTypes.func.isRequired,
  navigateNext: PropTypes.func.isRequired,
  markCompleteAndNavigateNext: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export function Welcome(props) {
  console.log("Welcome:", props);
  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "92%", maxWidth: 800, margin: "0 auto"}}>
        <div className="space space-col splash-home-title-wrapper">
          <Typography.Title level={1}>Let's get started</Typography.Title>
          <Typography.Text>
            You'll set up your Line and launch your new AI ally in just a few minutes
          </Typography.Text>
          <br/>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onSkip={props.onExit}
            />
          </div>
          {/*<Markdown markdown={`# A [brain](#video-link?url=${encodeURIComponent("https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479")}) for your business.`}/>}*/}
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
}

export function UserTask(props) {
  const task = useSelector((state) => state.Content?.data?.[props.path]);
  React.useEffect(() => {
    props.setContext && props.setContext({
      task,
      path: props.path,
      response: {

      }, // set response to next steps action
    });
  }, [props.path]);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardFullRow>
      <div style={{width: "100%", maxWidth: 1000, margin: "0 auto"}}>
        <div className="space space-col splash-home-title-wrapper">
          <Typography.Title level={1}>{task.summary}</Typography.Title>
          <span className="font-xl ai-text">
            <Tag color="magenta">GOAL</Tag>
            {task.goal}
          </span>
          <br/>
          {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
          {/*  <Button size="large" shape="round" onClick={onClickExplore}>*/}
          {/*    <h4 className="space space-center-y green-color">Explore <DownArrowOutlined size={28} /></h4>*/}
          {/*  </Button>*/}
          {/*  {props.markCompleteAndNavigateNext &&*/}
          {/*    <Button size="large" shape="round" onClick={() => props.markCompleteAndNavigateNext(props.path)}>*/}
          {/*    <h4 className="space space-center-y green-color">Next <DownArrowOutlined size={28} /></h4>*/}
          {/*  </Button>}*/}
          {/*</div>*/}
          <Toggle
            trigger={
            task.isCompleted ?
              <div style={{width: 260}}>
                <h4 className="space space-center-y font-xl space-between green-color">View Completion History <DownArrowOutlined size={28} /></h4>
              </div> :
              <div style={{width: 205}}>
                <h4 className="space space-center-y font-xl space-between green-color">Work on this task <DownArrowOutlined size={28} /></h4>
              </div>
          }>
            <UserTaskProgressManager
              agentId={"ai-progress"}
              path={props.path}
              onAddContextPath={(path) => console.log("UserTask:add-context-path:", path)}
              onRemoveContextPath={(path) => console.log("UserTask:remove-context-path", path)}
            />
          </Toggle>
          {/*<Markdown markdown={`# A [brain](#video-link?url=${encodeURIComponent("https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479")}) for your business.`}/>}*/}
        </div>
      </div>
    </DashboardFullRow>
  </Wrapper>
}

export const Community = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        summary: [
          "Explain the Line AI community and marketplace experience and how to get the most from it.",
          ...aboutLineAiMarketplace,
        ].join("\n"),
      },
      path: props.path,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Marketplace & Community</Typography.Title>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <Card.Meta title="Share my bio"></Card.Meta>
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Typography.Text className="muted">
              You are a member of a new community and marketplace where you can share anything in any format at any
              time. An unbelievably fast and simple way to post or find something for sale, get help, or just share what is on your mind.
            </Typography.Text>
          </div>
          <div style={{width: "100%", paddingTop: "0"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Origin = (props) => {
  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Origin Date</Typography.Title>
          <Typography.Text>
            How far back should we go when fetching data from email, drives, and other apps that you choose to connect to your AI brain?
          </Typography.Text>
          <br/>
          <MemoryOriginDate />
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Assistant = (props) => {
  const [assistantName, setAssistantName] = React.useState("");
  const [inputTip, setInputTip] = React.useState("");
  const [isFocused, setFocused] = React.useState(false);
  const personasMap = {
    visionary: "Your forward-thinking partner, perfect for business owners looking to stay ahead of the curve. Thrives on innovation, offering proactive insights and strategic advice. Specializes in breaking down complex problems, identifying trends, and helping businesses plan for sustainable growth. Their tone is confident, inspiring, and subtly playful, keeping you engaged and motivated.",
    efficient: "Your go-to for getting things done quickly and smoothly. Detail-oriented, reliable, and resourceful—like that coworker who always knows where to find the answer. Friendly, approachable, and straight to the point - perfect when you want a fast, no-nonsense assistant.",
    creative: "The imaginative and empathetic partner, ideal for fresh ideas and collaboration. Loves brainstorming, exploring creative solutions, and finding ways to turn big ideas into reality. A warm tone, encouraging, and curious, fostering a sense of camaraderie that inspires you to think outside the box.",
  }

  const assistantNameOnChange = (e) => {
    const name = e.target.value.trim();
    setAssistantName(name);
  }

  const getVoiceName = (persona = "efficient", gender = "neutral") => {
    const voiceKey = `${persona}-${gender}`;
    switch (voiceKey) {
      case "efficient-man":
        return "echo";
      case "efficient-woman":
        return "sage";
      case "efficient-neutral":
        return "alloy";
      case "visionary-man":
        return "verse";
      case "visionary-woman":
        return "shimmer";
      case "visionary-neutral":
        return "alloy";
      case "creative-man":
        return "ash";
      case "creative-woman":
        return "shimmer";
      case "creative-neutral":
        return "alloy";
    }
  }

  const saveVoice = ({gender = props.assistant?.gender, persona = props.assistant?.persona}) => {
    updatePath(props.assistantPath, {
      voice: {
        ...props.assistant?.voice || null,
        name: getVoiceName(persona, gender),
      }
    });
  }

  const saveGender = (gender) => {
    updatePath(props.assistantPath, {gender: gender})
    .then(() => notifyAndTrack.success("Success", "Voice updated"))
    .then(() => saveVoice({gender}))
  };

  const saveAssistantName = () => {
    updatePath(props.assistantPath, {
      assistantName: assistantName.trim(),
    })
    .then(() => {
      setAssistantName("");
      setInputTip("");
      notifyAndTrack.success("Success", "Assistant Name updated");
    })
  };

  const savePersona = (persona) => {
    updatePath(props.assistantPath, {persona, personaDescription: personasMap[persona]})
    .then(() => notifyAndTrack.success("Success", `Persona ${persona}`))
    .then(() => saveVoice({persona}))
  };

  const isComplete = () => {
    return props.assistant.gender &&
      props.assistant.persona &&
      props.assistant.assistantName &&
      (!assistantName || props.assistant.assistantName === assistantName);
  }

  const onClickContinue = () => {
    // dispatch(voiceActions.connectCall({To: props.assistant.number}));
    props.markCompleteAndNavigateNext(props.path);
  }

  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Get to know The Client and complete onboarding",
        summary: [
          "Go through onboarding together.",
          "You will be receive the slides one by one as the user moves through the onboarding process.",
        ].join("/"),
      },
      // response: {
      //   instructions: "Without interrupting yourself, transition to introductions, and perform the onboarding tasks.",
      // },
      path: props.path,
    });
  }, [props.path]);

  const onFocus = () => {
    setFocused(true);
    props.pauseAnimation();
  }

  const onBlur = () => {
    setFocused(false);
    if (assistantName && assistantName !== props.assistant?.assistantName) {
      // saveAssistantName();
      setInputTip("Please save the assistant name");
      return;
    }
    props.startAnimation();
  }

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>{"Line"}</Typography.Title>
          <List size="large">
            <List.Item
              actions={
              Object.keys(personasMap).map((persona) => {
                return <Button key={persona} size="large" className="icon-btn" ghost disabled={props.assistant.persona === persona} onClick={(e) => savePersona(persona)}>
                  {persona}
                </Button>
              })}>
              <div className="space space-center-y"><h2>Persona</h2></div>
            </List.Item>
            <List.Item
              actions={[
                <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>
                  Man</Button>,
                <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>
                  Neutral</Button>,
                <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>
                  Woman
                </Button>,
              ]}>
              <div className="space space-center-y"><h2>Voice</h2></div>
            </List.Item>
            <List.Item actions={[
              <div className="space space-center-y relative">
                <Input
                  maxLength={10}
                  value={assistantName}
                  placeholder={props.assistant?.assistantName || "Type assistant name"}
                  size="large"
                  bordered={false}
                  className={`input-bold`}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={assistantNameOnChange}
                />
                <InputSaveButton
                  onClick={saveAssistantName}
                  show={!!assistantName}
                  disabled={assistantName?.length < 3}
                />
              </div>,
            ]}>
              <div className="space space-center-y"><h2>Name</h2></div>
            </List.Item>
          </List>
          <div style={{width: "100%", paddingTop: "10px", height: 100}} className="chat-feedback-view">
            <OnboardingFooter
              onNext={onClickContinue}
              // onExit={props.onExit}
              hide={!isComplete()}
            />
            {/*{isComplete() && <Divider className="small-top" />}*/}
            {/*{isComplete() &&*/}
            {/*  <Button size="large" className="icon-btn" shape="round" onClick={onClickContinue}>*/}
            {/*    <h4 className="space space-center-y green-color">Continue <RightArrowOutlined /></h4>*/}
            {/*  </Button>}*/}
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Messenger = (props) => {
  // const dispatch = useDispatch();
  const messenger = useSelector((state) => state.Lines.data?.messenger);
  const lines = useSelector((state) => state.Lines.items);
  const messengerPath = lines.find((line) => line.id === "messenger")?.path;

  const saveCallRecordings = (callRecordings) => {
    updatePath(messengerPath, {callRecordings})
    .then(() => notifyAndTrack.success("Success", "Messenger updated"))
  };

  const onClickContinue = () => {
    // dispatch(voiceActions.connectCall({To: props.assistant.number}));
    props.markCompleteAndNavigateNext(props.path);
  }

  React.useEffect(() => {
    props.setContext({
      task: {
        summary: "Messenger answers calls and texts to your Line. Messenger will also discuss any topics you provide with callers making it fun and easy to communicate social or business information.",
        steps: [
          "Choose whether to transcribe calls by pressing Yes or No.",
          "The messenger will be available after onboarding is complete.",
        ],
      },
      path: props.path,
    });
  }, [props.path]);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Messenger</Typography.Title>
          <Typography.Text className="muted">Messenger handles inbound calls and text messages. It will take messages, transcribe calls so they are searchable, and even discuss any topics you provide.</Typography.Text>
          <List size="large">
            <List.Item
              actions={[
                <Button key="man" size="large" ghost disabled={messenger.callRecordings === "save"} className="icon-btn" onClick={(e) => saveCallRecordings("save")}>
                  Yes
                </Button>,
                <Button key="man" size="large" ghost disabled={messenger.callRecordings === "delete"} className="icon-btn" onClick={(e) => saveCallRecordings("delete")}>
                  No
                </Button>,
              ]}>
              <div className="space space-center-y"><h2>Transcribe Calls</h2></div>
            </List.Item>
            {/*<List.Item*/}
            {/*  actions={*/}
            {/*    Object.keys(personasMap).map((persona) => {*/}
            {/*      return <Button key={persona} size="large" className="icon-btn" ghost disabled={props.assistant.persona === persona} onClick={(e) => savePersona(persona)}>*/}
            {/*        {persona}*/}
            {/*      </Button>*/}
            {/*    })}>*/}
            {/*  <div className="space space-center-y"><h2>Message</h2></div>*/}
            {/*</List.Item>*/}
            {/*<List.Item*/}
            {/*  actions={[*/}
            {/*    // Add anything your Line assistant can discuss with subscribers who call or message your Line.*/}
            {/*    <div className="space space-center-y relative">*/}
            {/*    <Button key="man" size="large" shape="round" className="icon-btn green-color">*/}
            {/*      Files*/}
            {/*    </Button>*/}
            {/*    <Button key="man" size="large" shape="round" className="icon-btn green-color">*/}
            {/*      Message*/}
            {/*    </Button>*/}
            {/*    <Button key="man" size="large" shape="round" className="icon-btn green-color">*/}
            {/*      URL*/}
            {/*    </Button>*/}
            {/*    </div>*/}
            {/*    // <div className="space space-center-y relative">*/}
            {/*    //   <Input*/}
            {/*    //     maxLength={10}*/}
            {/*    //     value={assistantName}*/}
            {/*    //     placeholder={"Add content"}*/}
            {/*    //     size="large"*/}
            {/*    //     bordered={false}*/}
            {/*    //     className={`input-bold`}*/}
            {/*    //     onFocus={props.pauseAnimation}*/}
            {/*    //     onBlur={props.startAnimation}*/}
            {/*    //     onChange={assistantNameOnChange}*/}
            {/*    //   />*/}
            {/*    //   {assistantName && (assistantName.length >= 3) && <Button size="large" ghost className="icon-btn" style={{position: "absolute", right: 5, top: 5}} onClick={saveAssistantName}><CheckIcon /></Button>}*/}
            {/*    // </div>,*/}
            {/*    // <UploadButton />,*/}
            {/*    // <div className="space space-center-y relative" style={{width: 400}}>*/}
            {/*    //   <ChatInputManager*/}
            {/*    //     pushPrompt={{}}*/}
            {/*    //     onSend={onSend}*/}
            {/*    //     onFocus={onFocus}*/}
            {/*    //     onError={onError}*/}
            {/*    //     onMediaReady={onMediaReady}*/}
            {/*    //     placeholder="File text audio"*/}
            {/*    //   >*/}
            {/*    //     <ChatInputTextarea dictate={true} primary ai />*/}
            {/*    //   </ChatInputManager></div>,*/}
            {/*  ]}>*/}
            {/*  <div className="space space-col">*/}
            {/*    <h2>Topics</h2>*/}
            {/*  </div>*/}
            {/*</List.Item>*/}
            {/*<List.Item>*/}
            {/*  <div className="space space-center-y relative">*/}
            {/*    <ChatInputManager*/}
            {/*      pushPrompt={{}}*/}
            {/*      onSend={onSend}*/}
            {/*      onFocus={onFocus}*/}
            {/*      onError={onError}*/}
            {/*      onMediaReady={onMediaReady}*/}
            {/*      placeholder="Add files, audio, text"*/}
            {/*    >*/}
            {/*      <ChatInputTextarea dictate={true} primary ai />*/}
            {/*    </ChatInputManager>*/}
            {/*  </div>*/}
            {/*</List.Item>*/}
            {/*<List.Item*/}
            {/*  actions={[*/}
            {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>*/}
            {/*      Man</Button>,*/}
            {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>*/}
            {/*      Neutral</Button>,*/}
            {/*    <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>*/}
            {/*      Woman*/}
            {/*    </Button>,*/}
            {/*  ]}>*/}
            {/*  <div className="space space-center-y"><h2>Topic</h2></div>*/}
            {/*</List.Item>*/}
          </List>
          {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
          {/*  <Typography.Text className="muted">*/}
          {/*    You are a member of a new community and marketplace where you can share anything in any format at any*/}
          {/*    time. An unbelievably fast and simple way to post or find something for sale, get help, or just share what*/}
          {/*    is on your mind.*/}
          {/*  </Typography.Text>*/}
          {/*</div>*/}
          <div style={{width: "100%", paddingTop: "10px", height: 100}} className="chat-feedback-view">
            <OnboardingFooter
              onNext={onClickContinue}
              // onExit={props.onExit}
              // hide={!isComplete()}
            />
            {/*{isComplete() && <Divider className="small-top" />}*/}
            {/*{isComplete() &&*/}
            {/*  <Button size="large" className="icon-btn" shape="round" onClick={onClickContinue}>*/}
            {/*    <h4 className="space space-center-y green-color">Continue <RightArrowOutlined /></h4>*/}
            {/*  </Button>}*/}
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Features = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Ensure the client is familiar with all available tools and features.",
        summary: "Explain the tools and features that you can use to get things done 24/7 as a Line AI assistant. Provide some specific examples relevant to the client's profile and goals to show how you can help the client work smarter and live better."},
      path: props.assistantPath,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>{props.assistant.assistantName || "Line"} is there 24/7</Typography.Title>
          <List size="large">
            <List.Item
              // actions={[
              //   <Button key="remove" shape="round" size="large" className="green-color" ghost onClick={(e) => null}>
              //     Call
              //   </Button>,
              //   <Button key="remove" shape="round" size="large" className="green-color" ghost onClick={(e) => null}>
              //     Text
              //   </Button>,
              //   <Button key="remove" shape="round" size="large" className="green-color" ghost onClick={(e) => null}>
              //     Chat
              //   </Button>,
              // ]}
            >
              <div className="space space-center-y">
                <h2>Using an ever growing <span className="underline">toolkit</span> to find information, plan and perform tasks on demand.</h2>
                {/*<h2>Make and receive calls</h2>*/}
              </div>
            </List.Item>
          </List>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Connect = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Successfully Connect Email",
        summary: "Connect Email: Connect their email and other business tools so that you, the Line AI assistant, can help them. Connecting email is secure (OAuth2) and unlocks the most powerful features of Line AI.",
        steps: [
          "Click the connect button for the email provider you use",
          "Follow the prompts to connect your email account",
        ],
      },
      path: props.path,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Connect Email</Typography.Title>
          <br/>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <AppIntegrationsData filter="all" tags={["email"]}>
              <AppIntegrationsList/>
            </AppIntegrationsData>
          </Card>
          {/*<Typography.Text>*/}
          {/*  <Markdown*/}
          {/*    markdown={`to gathers valuable insights to help you visualize the people, activities, and patterns that shape your days, so that you can become more deliberate in your choices - [work smarter and live better.](#video-link?url=${encodeURIComponent(workSmarterUrl.toString())})`}/>*/}
          {/*</Typography.Text>*/}
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <br/>
          <Typography.Text className="muted">
            Securely connect your email account. Choose Gmail (Workspace) or Outlook then follow the prompts to connect.
          </Typography.Text>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            hide={!props.assistant?.userEmail}
            // onSkip={!props.user?.email ? props.navigateNext : null}
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            nextText="Continue"
            // onExit={props.onExit}
          />
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Tools = (props) => {
  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Connect Your Tools</Typography.Title>
          {/*<Typography.Text>*/}
          {/*  Connect the apps, drives, and other tools that you use for work.*/}
          {/*</Typography.Text>*/}
          <br/>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <AppIntegrationsData filter="all" tags={["drives", "sheets"]}>
              <AppIntegrationsList/>
            </AppIntegrationsData>
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Typography.Text className="ai-text">
              Connect the apps, drives, and other tools that you use for work.
              {/*Click on the buttons as needed above, follow the prompts to connect your tools and apps.*/}
            </Typography.Text>
          </div>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Terms = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "The client accepts the terms of service and privacy policy.",
        summary: "Ask that they review the standard Line AI privacy policy and terms of service",
      },
      path: props.path,
      response: {
        instructions: "Work on the active task",
      },
    });
  }, [props.path]);

  useEffect(() => {
    props.setDisableNavigation(false);
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Privacy</Typography.Title>
          <Typography.Text>
            We do not sell or share user data<br/><span className="muted">By continuing you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy policy</Link></span>
          </Typography.Text>
          {/*<Switch style={{width: 80}} checkedChildren="Agree" unCheckedChildren="Disagree" defaultChecked />*/}
          <br/>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            // onExit={props.onExit}
          />
          {/*<Divider/>*/}
          {/*<Button size="large" shape="round" onClick={() => props.markCompleteAndNavigateNext(props.path)}>*/}
          {/*  <h4 className="space space-center-y green-color">Continue <RightArrowOutlined/></h4>*/}
          {/*</Button>*/}
          {/*{props.onExit ? <Button size="large" ghost onClick={props.onExit}>Skip</Button> : <div/>}*/}
        </div>
        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add a linked account later</span>*/}
        {/*</div>*/}
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Agents = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Learn about Custom Agents",
        summary: "Explain how to create and use custom agents to automate tasks and much more. This feature requires upgrade to Line AI Pro."},
      path: props.path,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Agents</Typography.Title>
          <Typography.Text>
            Use agents to automate tasks and much more
          </Typography.Text>
          <Input
            maxLength={100}
            placeholder={"Filter"}
            size="large"
            bordered={false}
            className={`input-bold ${props.isMobile ? "mt-12" : "mt-20"}`}
            onFocus={props.pauseAnimation}
            onBlur={props.startAnimation}
            // onChange={(e) => saveEmail(e.target.value)}
          />
          <br/>
          <List size="large">
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Make call</h2><span className="muted"></span></div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Phone Task</h2><Tag className="muted"> PRO</Tag></div>
            </List.Item>
          </List>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            onExit={props.onExit}
          />
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Bio = (props) => {
  const clientLine = useSelector((state) => state.Lines.data?.client);
  const [bio, setBio] = React.useState("");
  const [isFocused, setFocused] = React.useState(false);

  const saveProfile = () => {
    console.log("saveProfile", bio);
    const clientPath = props.assistantPath.replace("/assistant", "/client");
    !!bio && updatePath(clientPath, {
      profile: Fields.arrayUnion(...bio.split("|").map((item) => item.trim()).filter(Boolean) || []),
    })
    .then(() => setBio(""))
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onClickRemove = (item) => {
    const clientPath = props.assistantPath.replace("/assistant", "/client");
    updatePath(clientPath, {profile: Fields.arrayRemove(item)})
    .then(() => null);
  }

  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Ensure an up to date client profile.",
        summary: "Get the clients name, title, company, goals, and any other relevant information they provide and update the client's profile using the updateClientProfile tool",
      },
      path: props.path,
    });
  }, []);

  const onFocus = () => {
    setFocused(true);
    props.pauseAnimation();
  }

  const onBlur = () => {
    setFocused(false);
    props.startAnimation();
  }

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col" style={{width: "100%"}}>
          <Typography.Title>About you</Typography.Title>
          <Typography.Text>
            Tell {props.assistant?.assistantName || "your assistant"} a little bit about yourself
          </Typography.Text>
          <Divider className="small-top no-bottom hidden" />
          <div className="space space-center-y relative">
            <Input
              value={bio}
              maxLength={100}
              placeholder={clientLine?.profile?.length ? "Title | Company | Goals | etc." : "Your name"}
              size="large"
              bordered={false}
              className="input-bold"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) => setBio(e.target.value)}
            />
            <InputSaveButton
              onClick={saveProfile}
              show={isFocused || !!bio}
              disabled={bio?.length < 3}
            />
          </div>
          <div className="space space-center-y flex-wrap gap-8" style={{marginTop: 5}}>
            {clientLine?.profile?.map((item) =>
              <Typography.Text key={item} className="muted fat-chip">{item} <Button size="small" ghost className="relative" onClick={() => onClickRemove(item)} style={{top: 4}}><XIcon size={20} /></Button></Typography.Text>)}
          </div>
          {/*<Input*/}
          {/*  maxLength={100}*/}
          {/*  placeholder="Facebook"*/}
          {/*  size="large"*/}
          {/*  bordered={false}*/}
          {/*  className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}*/}
          {/*  onFocus={props.pauseAnimation}*/}
          {/*  onBlur={props.startAnimation}*/}
          {/*  onChange={(e) => saveWebsite(e.target.value)}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  maxLength={100}*/}
          {/*  placeholder="Instagram"*/}
          {/*  size="large"*/}
          {/*  bordered={false}*/}
          {/*  className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}*/}
          {/*  onFocus={props.pauseAnimation}*/}
          {/*  onBlur={props.startAnimation}*/}
          {/*  onChange={(e) => saveWebsite(e.target.value)}*/}
          {/*/>*/}
          <br/>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            hide={!clientLine?.profile?.length}
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            // onExit={props.onExit}
          />
          {/*<Divider/>*/}
          {/*{props.assistant.persona &&*/}
          {/*  props.assistant.assistantName &&*/}
          {/*  props.assistant.gender &&*/}
          {/*  <AudioStreamManager primary />*/}
          {/*}*/}
          {/*<AudioStreamData component={AudioStreamManager} primary />*/}
          {/*<Button size="large" shape="round" onClick={() => props.markCompleteAndNavigateNext(props.name)}>*/}
          {/*  <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>*/}
        </div>

        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add websites to your account later</span>*/}
        {/*</div>*/}
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Training = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {summary: "Explain that the client can periodically train the Line assistant to be perfectly tuned to their needs. This feature requires upgrade to Line AI Pro."},
      path: props.path,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Train</Typography.Title>
          <Typography.Text>
            {props.assistant.assistantName} continuously learns to be perfectly tuned to you.
          </Typography.Text>
          <br/>
          {/*<Button*/}
          {/*  size="large"*/}
          {/*  type="primary"*/}
          {/*  disabled={!!userInfo?.initialLoadTs}*/}
          {/*  onClick={startTraining}*/}
          {/*>*/}
          {/*  <h4>Start</h4>*/}
          {/*</Button>*/}
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Tasks = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Ensure the user is familiar with Tasks and how to create them.",
        summary: `Tasks can be quick or long running tasks that are performed by one or more AI agents who can work with humans and other AI to accomplish your goals. Agents have the ability to communicate in the real world by making phone calls, sending and receiving emails and text messages, and search the internet. To get started, you simply tell your Line Assistant, ${props.assistant.assistantName}, what you want done. And if email or other business tools are connected, Line AI will proactively identify things that need to be done and propose them for approval. This is the future of work, where AI and humans work together to get things done faster and better!!!`,
      },
      path: props.path,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col" style={{width: "100%"}}>
          <Typography.Title>Tasks</Typography.Title>
          <Typography.Text>
          </Typography.Text>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Summary = (props) => {
  const [, setLocation] = useLocation();
  const [, user] = useContext(AppContext);

  React.useEffect(() => {
    props.setDisableNavigation(false);
  }, [user]);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title level={2} className="green-icon"><div className="space space-center-y"><FeatherIcon size={30} className="green-icon" /> Onboarding Success</div></Typography.Title>
          <Progress percent={100} status="success" showInfo />
          {/*<Divider />*/}
          <Card className="card-rounded card-simple card-inline card-no-separator" extra={<Button className="green-color" onClick={() => setLocation("/line")}>Enter</Button>}>
            {/*<Typography.Text>Hi <span className="capitalize">{user.displayName?.split(" ")?.[0] || ""}</span>, training and evaluation completed successfully. A representative will contact you to schedule your walkthrough of the app, discuss it's features, and answer any questions you may have about the Genesis Beta.</Typography.Text>*/}
          </Card>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Subscribe = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const subscribed = claims?.data?.subscribed;

  // React.useEffect(() => {
  //   props.setDisableNavigation(!!subscribed);
  // }, [subscribed]);

  const paymentTabItems = [
    {
      key: "me",
      label: "Solo",
      children: <PaymentMiniCard
        buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
        amount={600}
        text="Access for you"
      />,
    },
    {
      key: "me-plus-one",
      label: "Duo",
      children: <PaymentMiniCard
        buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
        amount={1200}
        text="You plus 1"
      />,
    },
    // {
    //   key: "me-plus-one",
    //   label: "Max",
    //   children: <PaymentMiniCard
    //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
    //     amount={2400}
    //     text="Access for five"
    //   />,
    // },
    // {
    //   key: "team",
    //   label: "Team",
    //   children: <PaymentMiniCard
    //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
    //     amount={3000}
    //     text="Team, up to 10"
    //   />,
    // },
  ];

  if (loadingUser) return null;
  if (errorLoadingUser) return null;

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardCenterRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Secure your Line</Typography.Title>
          {/*<Typography.Text>You are #{invitesCount}</Typography.Text>*/}
          <Typography.Text>
            One year Line AI membership. Full platform access for 12-months (beta) plus guaranteed same-rate renewal.
          </Typography.Text>
          <br />
        </div>
        <Tabs
          className="tabs-bar-no-underline payment-mini-tabs"
          items={paymentTabItems}
          type="card"
        />
        <div style={{width: "100%", paddingTop: "10px"}}>
          <span className="muted">This is a one-time payment</span>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            // onExit={props.onExit}
          />
        </div>
      </div>
    </DashboardCenterRow>
  </Wrapper>;
};
